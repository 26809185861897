import { useContext } from "react";
import { useMediaQuery } from "react-responsive";
import ReactPlayer from "react-player";
import State from "../../../../context/manager/State";
import { Flex, theme, Typography } from "antd";
import ModalQuarterlyGoals from "../../../../organisms/ModalQuarterlyGoals";
import SectionAdvisorDashboardAnalytics from "../../../../organisms/SectionAdvisorDashboardAnalytics";

import StylesContainer from "./styles";

import content from "./content.js";

import { updateQuarterlyGoals } from "../../../../utils/requests/manager";

const { Title, Paragraph } = Typography;

const PageLevelAdvisorDash = () => {
  const { token } = theme.useToken();
  const [adminState] = useContext(State);
  const isTablet = useMediaQuery({ maxWidth: token.screenLGMin });

  const handleUpdateQuarterlyGoals = requestBody => {
    adminState.showLoader();

    updateQuarterlyGoals(requestBody)
      .then(() => {
        adminState.closeModal("quarterlyGoalsModal");
        adminState.showSuccess("Quarterly Goals updated.");
        adminState.setAdminData();
      })
      .catch(console.log);
  };

  return (
    <StylesContainer wrap={isTablet}>
      <SectionAdvisorDashboardAnalytics />
      <Flex
        gap={23}
        style={{
          width: 364,
          height: "fit-content",
          background: token.colorGrey3,
          borderRadius: 8,
          padding: 22,
        }}
        vertical
      >
        <Flex gap={13} vertical>
          <Title
            style={{
              fontSize: 21,
              fontWeight: 300,
              color: token.colorLabel,
              margin: 0,
            }}
          >
            Level The Playing Field...
          </Title>
          <ReactPlayer
            url={adminState.video ?? token.advisorDashboardPageVideoLink}
            playing={true}
            muted={true}
            width={320}
            height={195}
          />
        </Flex>
        {content.asideCards.map(({ title, description, Icon }, index) => (
          <Flex className={"aside-card"} key={index} gap={16} align="start">
            <Icon style={{ minWidth: 30 }} />
            <Flex vertical>
              <Title
                style={{
                  fontSize: 16,
                  fontWeight: 500,
                  color: token.colorUiText,
                  margin: "0",
                }}
              >
                {title}
              </Title>
              <Paragraph style={{ color: token.colorUiText }}>
                {description}
              </Paragraph>
            </Flex>
          </Flex>
        ))}
      </Flex>

      <ModalQuarterlyGoals
        open={adminState.quarterlyGoalsModal}
        onClose={() => adminState.closeModal("quarterlyGoalsModal")}
        handleUpdateQuarterlyGoals={handleUpdateQuarterlyGoals}
        initialState={adminState.quarterly}
        loading={adminState.loading}
      />
    </StylesContainer>
  );
};

export default PageLevelAdvisorDash;
