import styled from "styled-components";
import { Flex } from "antd";

const StylesContainer = styled(Flex)`
  background: #ffffff;
  color: #03494e;
  padding: 26px 23px;
  border-radius: 12px;
  box-shadow: 0 0 16px #0000000d;

  .name {
    font-size: 18px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
  }

  .phone {
    min-width: 100px;
  }

  .email {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
  }
`;

export default StylesContainer;
