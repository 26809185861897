const IconGoals = () => (
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="30"
    viewBox="0 0 32 30"
  >
    <g transform="translate(-26 -254)">
      <path
        d="M13.5,3A10.5,10.5,0,1,0,24,13.5,10.512,10.512,0,0,0,13.5,3m0-3A13.5,13.5,0,1,1,0,13.5,13.5,13.5,0,0,1,13.5,0Z"
        transform="translate(29 254)"
      />
      <path
        d="M8.5,3A5.5,5.5,0,1,0,14,8.5,5.506,5.506,0,0,0,8.5,3m0-3A8.5,8.5,0,1,1,0,8.5,8.5,8.5,0,0,1,8.5,0Z"
        transform="translate(34 259)"
      />
      <circle cx="3.5" cy="3.5" r="3.5" transform="translate(39 264)" />
    </g>
  </svg>
);

export default IconGoals;
