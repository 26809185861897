export const getChartConfigPlanning = ({
  labelAccentBackground,
  labelAccentColor = "#fff",
  labelAccentValue,
  series = [],
  width = 320,
}) => ({
  chart: {
    backgroundColor: "transparent",
    height: 400,
    polar: true,
    spacingRight: 0,
    spacingTop: 0,
    type: "line",
    width,
  },
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  legend: {
    align: "right",
    floating: true,
    itemStyle: {
      color: "#000",
      fontFamily: "Inter, sans-serif",
      fontSize: 12,
      fontWeight: 500,
    },
    layout: "vertical",
    verticalAlign: "bottom",
  },
  pane: {
    size: "60%",
  },
  plotOptions: {
    marker: {
      enabled: false,
    },
  },
  series: series,
  title: {
    text: "",
  },
  tooltip: {
    outside: true,
    pointFormat:
      '<span style="color:{series.color}">{series.name}: <b>' +
      "{point.y:,.0f}</b><br/>",
    shared: true,
  },
  xAxis: {
    categories: ["Spending", "Giving", "Saving", "Investing"],
    gridLineColor: "#B1B1B1",
    labels: {
      formatter: function () {
        return this.value === labelAccentValue
          ? `<span style="background: ${labelAccentBackground}; border-radius: 12px; color: ${labelAccentColor}; padding: 10px;">${this.value}</span>`
          : this.value;
      },
      style: {
        color: "#404040",
        fontSize: 16,
      },
      useHTML: true,
    },
    lineWidth: 0,
    tickmarkPlacement: "on",
    offset: 15,
  },
  yAxis: {
    gridLineInterpolation: "polygon",
    labels: {
      enabled: false,
    },
    lineWidth: 0,
    min: 0,
  },
});
