import { useContext } from "react";
import State from "../../../../context/manager/State";
import { Button, Flex, Tabs } from "antd";
import ModalArchiveManagedUser from "../../../../molecules/ModalArchiveManagedUser";
import ModalImportManagedUser from "../../components/ModalImportManagedUser";
import ModalLeadDetails from "../../components/ModalLeadDetails";
import ModalLeadQuestion from "../../components/ModalLeadQuestion";
import ModalReleaseLead from "../../components/ModalReleaseLead";
import SectionMyLeads from "./components/SectionMyLeads";
import SectionNextLeads from "./components/SectionNextLeads";
import SecurityGate from "../../../../providers/SecurityGate";

import StylesContainer from "./styles";

import { DEFAULT_BIRTH_YEAR } from "../../../../utils/constants";

import {
  addLead,
  archiveLead,
  convertLeadToClient,
  editLead,
  importCSVManagedUserLead,
  openGoalsManagedUser,
  openManagedUser,
  openProposal,
  releaseLead,
  setLastAdvisorLogin,
} from "../../../../utils/requests/manager";
import { isOrganizationAstor } from "../../../../utils/helpers/specialized";

import IconPenny from "../../images/IconPenny";
import { ReactComponent as DownloadIcon } from "./images/download.svg";

const isMyLeadUser = userData => {
  if (userData?.valueMap?.leadInitial?.isConverted) {
    return false;
  } else if (
    userData?.entryPoint === "addedByUserManager" &&
    (userData?.userType === "Assigned" || userData?.userType === "Prospect")
  ) {
    return true;
  } else if (
    userData?.entryPoint === "addedByItself" &&
    userData?.userType === "Affiliated"
  ) {
    return true;
  } else {
    return false;
  }
};

const PageLeads = ({ hideHeader }) => {
  const [managerState] = useContext(State);

  const myLeadsList =
    managerState.managedUsers
      ?.filter(it => isMyLeadUser(it))
      ?.map((it, index) => ({ ...it, key: index })) ?? [];

  const handleArchiveLead = () => {
    managerState.closeModal("archiveLeadModal");
    managerState.showLoader();

    archiveLead({
      email: managerState.activeEmail,
      userManagerEmail: managerState._id,
    })
      .then(() =>
        managerState.setAdminData().then(() => {
          managerState.hideLoader();
          managerState.setKeyValue("activeEmail", null);
          managerState.showSuccess(`Lead ${managerState.activeEmail} archived`);
        })
      )
      .catch(error => {
        console.log(error);
        managerState.hideLoader();
      });
  };

  const handleArchiveLeadModalClose = () => {
    managerState.closeModal("archiveLeadModal");
    managerState.setKeyValue("activeEmail", null);
  };

  const handleLeadQuestionModalClose = () => {
    managerState.closeModal("leadQuestionModal");
  };

  const handleMoveLeadToClient = email => {
    convertLeadToClient(email)
      .then(() =>
        managerState
          .setAdminData()
          .then(() =>
            managerState.showSuccess(`Lead ${email} converted into client.`)
          )
      )
      .catch(console.log);
  };

  const handleReleaseLead = () => {
    managerState.showLoader();

    releaseLead({ email: managerState.activeEmail })
      .then(response => {
        managerState.showSuccess(response?.data);
        managerState.closeModal("releaseLeadModal");

        managerState.setAdminData(() => {
          managerState.setKeyValue(managerState.activeEmail, null);
          managerState.hideLoader();
        });
      })
      .catch(error => {
        console.log(error);
        managerState.hideLoader();
      });
  };

  const handleReleaseLeadModalClose = () => {
    managerState.closeModal("releaseLeadModal");
    managerState.setKeyValue("activeEmail", null);
  };

  const handleExportLeads = () => {
    window.open(
      `${process.env.REACT_APP_API_GATEWAY}/userManager/export?email=${encodeURIComponent(managerState._id)}&userType=Lead`
    );
  };

  const handleImportLeads = file => {
    importCSVManagedUserLead(file)
      .then(response => {
        if (typeof response?.data === "object") {
          Object.keys(response.data).map(key =>
            managerState.showWarning(`${key} ${response.data[key]}`)
          );
        }

        managerState.setAdminData().then(() => {
          managerState.hideLoader();
          managerState.closeModal("importManagedUserModal");
          managerState.showSuccess("Leads list imported.");
        });
      })
      .catch(error => {
        managerState.hideLoader();
        managerState.showError(error.response?.data);
      });
  };

  const handleLeadQuestionAnswerSubmit = values => {
    const leadData = managerState.managedUsers.find(
      it => it.email === managerState.activeEmail
    );

    if (leadData) {
      managerState.showLoader();

      editLead({
        ...leadData,
        leadInitial: {
          ...leadData.valueMap?.leadInitial,
          ...values,
        },
      })
        .then(() =>
          managerState.setAdminData().then(() => {
            managerState.showSuccess("Answer sent.");
            managerState.closeModal("leadQuestionModal");
            managerState.hideLoader();
          })
        )
        .catch(error => {
          managerState.hideLoader();
          managerState.showError(error?.response?.data);
        });
    }
  };

  const tabsItems = [
    {
      children: (
        <SectionMyLeads
          handleMoveLeadToClient={handleMoveLeadToClient}
          myLeadsList={myLeadsList}
        />
      ),
      hidden:
        managerState.access?.next &&
        !managerState.access?.affiliate &&
        !managerState.access?.level &&
        !managerState.access?.proportal,
      key: 1,
      label: `My Leads (${myLeadsList?.length ?? 0})`,
    },
    {
      children: (
        <SectionNextLeads handleMoveLeadToClient={handleMoveLeadToClient} />
      ),
      disabled: !managerState.access?.next,
      hidden: isOrganizationAstor(managerState.organization?.name),
      key: 2,
      label: `Next Leads (${
        managerState.managedUsers?.filter(
          it =>
            it.entryPoint === "addedByOrgManager" &&
            (it.userType === "Assigned" || it.userType === "Prospect") &&
            !it.valueMap?.leadInitial?.isConverted
        )?.length ?? 0
      })`,
    },
  ].filter(it => !it.hidden);

  const handleOpenClientView = ({ planningType, userEmail }) => {
    if (managerState.access?.level) {
      openProposal({ userEmail });
    } else {
      planningType === "cashflow"
        ? openManagedUser(userEmail)
        : openGoalsManagedUser(userEmail);
    }

    setLastAdvisorLogin(userEmail).then(() => managerState.setAdminData());
  };

  const handleUpdateLeadDetails = values => {
    managerState.showLoader();

    if (managerState.activeEmail) {
      const leadData = managerState.managedUsers.find(
        it => it.email === managerState.activeEmail
      );

      editLead({
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        birthYear: leadData.birthYear,
        phoneNumber: values.phoneNumber,
        leadInitial: {
          ...leadData?.valueMap?.leadInitial,
          planningType: values.planningType,
          assets: values.assets,
          phoneAlt: values.phoneAlt,
        },
      }).then(() =>
        managerState.setAdminData().then(() => {
          managerState.hideLoader();
          managerState.closeModal("leadDetailsModal");
          managerState.setKeyValue("activeEmail", null);
        })
      );
    } else {
      addLead({
        ...values,
        birthYear: values.birthYear ?? DEFAULT_BIRTH_YEAR,
        leadInitial: {
          rate: 0,
          isNotifyAdvisor: values.isNotifyAdvisor,
        },
        phoneNumber: `${values.phoneNumber}`,
        userManagerEmail: managerState._id,
      })
        .then(response =>
          managerState.setAdminData().then(() => {
            managerState.showSuccess(response.data);
            managerState.closeModal("leadDetailsModal");
          })
        )
        .catch(error => {
          managerState.hideLoader();
          managerState.showError(error.response?.data);
        });
    }
  };

  return (
    <SecurityGate state={managerState}>
      <StylesContainer>
        {!hideHeader && (
          <Flex align={"center"} className={"header"} justify={"space-between"}>
            <h2 className={"title"}>Leads</h2>
            <Button
              disabled={!managerState.access?.penny}
              icon={<IconPenny />}
              onClick={() => managerState.openModal("openModalAskAdvice")}
              shape={"round"}
              type={"primary"}
            >
              <span>
                Ask<b>Penny</b>
              </span>
            </Button>
          </Flex>
        )}
        <div className="body">
          <Tabs
            tabBarExtraContent={
              <Button
                disabled={
                  !managerState.managedUsers?.filter(
                    it =>
                      (it.userType === "Assigned" ||
                        it.userType === "Unassigned") &&
                      !it.valueMap?.leadInitial?.isConverted
                  )?.length
                }
                onClick={handleExportLeads}
                shape={"round"}
                style={{ color: "#1B5568" }}
                type={"text"}
              >
                <Flex align={"center"} gap={6}>
                  <DownloadIcon />
                  Export All Leads
                </Flex>
              </Button>
            }
            items={tabsItems}
          />
        </div>

        <ModalArchiveManagedUser
          instanceName={"lead"}
          loading={managerState.loading}
          onCancel={handleArchiveLeadModalClose}
          onConfirm={handleArchiveLead}
          open={managerState.archiveLeadModal}
        />
        <ModalImportManagedUser
          instanceLabel={"lead"}
          loading={managerState.loading}
          onCancel={() => managerState.closeModal("importManagedUserModal")}
          onFinish={handleImportLeads}
          open={managerState.importManagedUserModal}
        />
        <ModalLeadDetails
          activeLeadData={myLeadsList?.find(
            it => it.email === managerState.activeEmail
          )}
          handleOpenClientView={handleOpenClientView}
          handleUpdateLeadDetails={handleUpdateLeadDetails}
          loading={managerState.loading}
          managerAccess={managerState.access}
          onCancel={() => managerState.closeModal("leadDetailsModal")}
          open={managerState.leadDetailsModal}
        />
        <ModalLeadQuestion
          activeUserData={managerState.managedUsers?.find(
            it => it.email === managerState.activeEmail
          )}
          loading={managerState.loading}
          open={managerState.leadQuestionModal}
          onCancel={handleLeadQuestionModalClose}
          onSubmit={handleLeadQuestionAnswerSubmit}
        />
        <ModalReleaseLead
          loading={managerState.loading}
          onCancel={handleReleaseLeadModalClose}
          onConfirm={handleReleaseLead}
          open={managerState.releaseLeadModal}
        />
      </StylesContainer>
    </SecurityGate>
  );
};

export default PageLeads;
