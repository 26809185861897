import styled from "styled-components";
import { Flex } from "antd";

const StylesContainer = styled(Flex)`
  margin: 45px auto;
  width: 950px;

  .header {
    .label {
      color: #000;
      font-family: Roboto, sans-serif;
      font-weight: 300;
      line-height: normal;
    }

    .title {
      color: #000;
      font-family: Roboto, sans-serif;
      font-size: 26px;
      font-weight: 700;
      line-height: normal;
      margin: 0;
    }
  }

  .tab-container {
    height: 485px;
    overflow: auto;

    .tab {
      background: #edf5f9;
      border-radius: 8px;
      color: #3c3c3c;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 16px;
      width: 168px;

      &.active {
        background: #689fb6;
        color: #fff;
      }

      .title {
        font-size: 19px;
        line-height: 140%;
        margin: 0;
      }

      .description {
        font-weight: 500;
        line-height: 140%;
        margin: 0;
      }
    }
  }

  .content-container {
    background: #e5f5f5;
    border-radius: 12px;
    padding: 28px;
    width: 767px;

    .description-container {
      width: 358px;

      .label {
        color: #7f99b2;
        font-weight: 700;
        line-height: 21px;
        text-transform: uppercase;
      }

      .title {
        color: #1e1e1e;
        font-size: 40px;
        line-height: 115%;
        margin: 0 0 16px;
      }

      .description {
        color: #1e1e1e;
        font-size: 16px;
        font-weight: 500;
        line-height: 150%;
      }
    }
  }
`;

export default StylesContainer;
