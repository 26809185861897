import styled from "styled-components";
import { Flex } from "antd";

const StylesContainer = styled(Flex)`
  font-family: Roboto, sans-serif;
  height: 100%;

  .title {
    color: #216a9c;
    font-size: 21px;
    line-height: normal;
    margin: 0;
  }

  .container-placeholder {
    background: linear-gradient(
        0deg,
        rgba(114, 213, 255, 0.2) 0%,
        rgba(114, 213, 255, 0.2) 100%
      ),
      #fff;
    border-radius: 12px;
  }

  .card-next-up {
    background: #114984;
    border-radius: 12px;
    color: #fff;
    line-height: normal;
    min-height: 357px;
    padding: 44px 37px 32px;
    text-align: center;
    width: 280px;

    .title {
      color: #fff;
      font-size: 21px;
    }

    .description {
      font-weight: 500;
      margin: 0;
    }
  }

  .ant-btn-custom {
    background: #4897d1;
    color: #fff;
    height: auto;
    line-height: normal;
    padding: 8px 16px;

    &:disabled {
      background: #cecece;
      color: #a2a2a2;
      opacity: 1;
    }
  }
`;

export default StylesContainer;
