import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Flex, Modal, Row, theme, Typography } from "antd";
import FilterCategory from "../../../../../../molecules/FilterCategory";

import { FilterHeading, Search } from "./styles";

import { SearchOutlined } from "@ant-design/icons";
import Close from "../../../../../../icons/Close";
import { ReactComponent as Filter } from "../../images/filter.svg";

const FiltersContainer = ({
  riskToleranceFilter,
  filteredProducts,
  productsList,
  productFiltersApplied,
  searchTerm,
  setSearchTerm,
  handleRiskToleranceFilterChange,
}) => {
  const { token } = theme.useToken();
  const isSmallScreen = useMediaQuery({ maxWidth: token.screenMDMax });
  const [filtersModalOpened, setFiltersModalOpened] = useState(false);

  return isSmallScreen ? (
    <Flex vertical>
      <Row
        wrap={false}
        align="middle"
        style={{ gap: 16, margin: "6px 0 30px" }}
      >
        <Search
          size="large"
          placeholder="Search..."
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          suffix={<SearchOutlined style={{ color: "rgba(0,0,0,.45)" }} />}
        />
        <Filter
          style={{ cursor: "pointer" }}
          onClick={() => setFiltersModalOpened(true)}
        />
      </Row>
      <Modal
        open={filtersModalOpened}
        title={
          <Typography.Text
            style={{ fontSize: 18, color: token.colorNavy }}
            strong
          >
            All Products{" "}
            <span style={{ fontWeight: 400 }}>({productsList.length})</span>
          </Typography.Text>
        }
        style={{ top: 36 }}
        onCancel={() => setFiltersModalOpened(false)}
        closeIcon={<Close />}
        footer={false}
      >
        <FilterHeading>Risk Tolerance</FilterHeading>
        <FilterCategory
          categoryKey="riskTolerance"
          categoriesList={[
            "Preservation",
            "Conservative",
            "Moderate",
            "Growth",
            "Equity",
          ]}
          defaultValues={riskToleranceFilter}
          onFilterChange={selectedValues => {
            handleRiskToleranceFilterChange(selectedValues);
          }}
          productFiltersApplied={productFiltersApplied}
          filteredProducts={filteredProducts}
          productsList={productsList}
        />
      </Modal>
    </Flex>
  ) : (
    <Flex vertical>
      <Typography.Text style={{ fontSize: 22, color: token.colorNavy }} strong>
        All Products{" "}
        <span style={{ fontWeight: 400 }}>({productsList.length})</span>
      </Typography.Text>
      <Search
        size="large"
        style={{
          margin: "20px 0 24px",
        }}
        placeholder="Search..."
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
        suffix={<SearchOutlined style={{ color: "rgba(0,0,0,.45)" }} />}
      />
      <FilterHeading>Risk Tolerance</FilterHeading>
      <FilterCategory
        categoryKey="riskTolerance"
        defaultValues={riskToleranceFilter}
        onFilterChange={selectedValues => {
          handleRiskToleranceFilterChange(selectedValues);
        }}
        productFiltersApplied={productFiltersApplied}
        filteredProducts={filteredProducts}
        productsList={productsList}
        categoriesList={["Allocation", "Equity", "Income"]}
      />
    </Flex>
  );
};

export default FiltersContainer;
