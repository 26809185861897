import styled from "styled-components";
import PageAdvisorDashboard from "../../../pages/PageAdvisorDashboard";
import PageLeads from "../../next/pages/PageLeads";
import PageLevelClients from "../../next/pages/PageLevelClients";
import PageMarketing from "../../../pages/PageMarketing";
import PageProducts from "../../../pages/PageProducts";
import ManagerHeader from "../components/ManagerHeader";

const Body = styled.div`
  padding: 32px 94px 94px 46px;
`;

export default [
  {
    path: "/astor-advisor-portal/dashboard",
    Component: () => (
      <>
        <ManagerHeader />
        <Body>
          <PageAdvisorDashboard />
        </Body>
      </>
    ),
  },
  {
    path: "/astor-advisor-portal/leads",
    Component: () => (
      <>
        <ManagerHeader />
        <PageLeads hideHeader />
      </>
    ),
  },
  {
    path: "/astor-advisor-portal/clients",
    Component: () => (
      <>
        <ManagerHeader />
        <Body>
          <PageLevelClients />
        </Body>
      </>
    ),
  },
  {
    path: "/astor-advisor-portal/products",
    Component: () => (
      <>
        <ManagerHeader />
        <Body>
          <PageProducts />
        </Body>
      </>
    ),
  },
  {
    path: "/astor-advisor-portal/marketing",
    Component: () => (
      <>
        <ManagerHeader />
        <Body>
          <PageMarketing />
        </Body>
      </>
    ),
  },
];
