import StyledCard from "./styles";

import { PRIORITIES_DEFAULT_STATE } from "../ModalPriorities/constant";
import { LIST_TENDENCY } from "../../../../constants";

export const priorityValuesList = [
  ...PRIORITIES_DEFAULT_STATE.step1.values,
  ...PRIORITIES_DEFAULT_STATE.step2.values,
  ...PRIORITIES_DEFAULT_STATE.step3.values,
  ...PRIORITIES_DEFAULT_STATE.step4.values,
];

export const getCleanSortableLabel = label =>
  label
    ?.replaceAll("[my]", "")
    ?.replaceAll("[spouse]", "")
    ?.replaceAll("[disabled]", "");

const SortableCard = ({ label, index, showIndex }) => {
  const isDisabled = label?.includes("[disabled]");

  const cleanLabel = getCleanSortableLabel(label);

  const tendencyData = LIST_TENDENCY.find(
    tendencyData =>
      tendencyData.value ===
      priorityValuesList.find(it => it.label === cleanLabel)?.type
  );

  return (
    <StyledCard
      className={isDisabled ? "disabled" : ""}
      color={tendencyData?.labelBackgroundColor}
    >
      {showIndex && <span className={"index-value"}>{index}</span>}
      {cleanLabel}
    </StyledCard>
  );
};

export default SortableCard;
