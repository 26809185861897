import styled from "styled-components";
import { Menu } from "antd";

export const StyledMenu = styled(Menu)`
  &.ant-menu {
    background: transparent;
    width: 100%;

    &.ant-menu-light.ant-menu-root.ant-menu-vertical {
      border-right: unset;
    }

    .ant-menu-item {
      align-items: center;
      display: flex;
      background: transparent;
      border-radius: unset;
      color: ${({ token }) => token.sidebarIconColor} !important;
      height: fit-content;
      line-height: 14px;
      margin: 0;
      min-height: 35px;
      padding: 0;
      width: 100%;

      .ant-menu-title-content {
        padding: 9px;
        width: 100%;
      }

      &.ant-menu-item-active .ant-menu-title-content {
        background: ${({ token }) => token.sidebarMenuItemHoverBackground};
        border-radius: 12px;
      }

      &:not(:first-of-type) {
        margin-top: 8px;
      }

      &.ant-menu-item-selected {
        color: ${({ token }) => token.sidebarIconColorActive} !important;

        &::before {
          background: #ffffff;
          border-radius: 3px 0 0 3px;
          content: "";
          height: 100%;
          position: absolute;
          right: 0;
          width: 6px;
        }
      }
    }
  }
`;
