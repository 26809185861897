import styled from "styled-components";

const StylesContainer = styled.div`
  .header {
    border-bottom: 1px solid #c9c8c8;
    padding: 16px 32px;

    .title {
      color: #1e1919;
      font-size: 20px;
      font-weight: 700;
      margin: 0;
    }
  }

  .body {
    box-sizing: border-box;
    padding: 32px 48px;
    overflow: auto;
  }

  .content-container {
    width: 980px;
  }
`;

export default StylesContainer;
