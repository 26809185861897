import { Image } from "antd";

import step_init from "../../images/step_init.png";

const StepInit = ({ Footer }) => (
  <>
    <Image preview={false} src={step_init} />
    <div
      style={{
        fontSize: 18,
        padding: "0 21px",
      }}
    >
      <p>
        <b>
          When you think about “financial planning” for you and/or your family,
          what are your highest priorities?
        </b>
      </p>
      <p>
        Please order each of the following four (4) categories from your{" "}
        <b>
          <u>highest priority</u>
        </b>{" "}
        at the top to your{" "}
        <b>
          <u>lowest priority</u>
        </b>{" "}
        at the bottom.
      </p>
      <ol>
        <li>
          There are{" "}
          <b>
            <u>no right or wrong answers.</u>
          </b>
        </li>
        <li>
          Give you{" "}
          <b>
            <u>honest, individual responses</u>
          </b>
          . In other words, don’t try to anticipate or capture what a loved one
          might say.
        </li>
        <li>
          You may use a{" "}
          <b>
            <u>short and/or long term view</u>
          </b>{" "}
          when responding. You may interpret each option however seems best to
          you.
        </li>
      </ol>
    </div>
    <Footer />
  </>
);

export default StepInit;
