import Proposal from "../templates/Proposal";

import IconGoals from "../../../icons/IconGoals";

export default [
  {
    path: "/proposal/dashboard",
    Component: Proposal,
    Icon: IconGoals,
    text: "Goals",
  },
];
