import { useContext } from "react";
import State from "../../../../context/manager/State";
import { Button, Row, Space, theme } from "antd";
import AnalyticValue from "../../../../atoms/AnalyticValue";
import ButtonDemoMode from "../../../../atoms/ButtonDemoMode";
import ModalManagedUserData from "../../../../organisms/ModalManagedUserData";
import SecurityGate from "../../../../providers/SecurityGate";
import TableLevelClients from "./components/TableLevelClients";

import { getCurrencyValueWithRoundSign } from "../../../../utils/helpers/general";

const PageLevelClients = () => {
  const { token } = theme.useToken();
  const [managerState] = useContext(State);

  const getTotalAum = () =>
    managerState.getManagedUsers().length
      ? managerState.getManagedUsers().reduce(
          (acc, it) =>
            it.valueMap?.productMap
              ? acc +
                Object.keys(it.valueMap.productMap)
                  .map(
                    key =>
                      it.valueMap.productMap[key][
                        it.valueMap.productMap[key].length - 1
                      ].investmentAmount
                  )
                  .reduce((acc, value) => acc + value, 0)
              : acc + (it.proposedAmount ?? 0),
          0
        )
      : 0;

  return (
    <SecurityGate state={managerState}>
      <Row justify={"space-between"}>
        <Space size={100} align="start" style={{ marginBottom: 20 }}>
          <AnalyticValue
            label={"Total Clients"}
            value={managerState.getManagedUsers().length}
          />
          <AnalyticValue
            label={"Total Proposed AUM"}
            value={getCurrencyValueWithRoundSign(getTotalAum())}
          />
        </Space>
        <Space size={14}>
          <ButtonDemoMode
            organization={managerState.organization?.name}
            userManagerEmail={managerState._id}
          />
          <Button
            type="primary"
            size="small"
            shape="round"
            style={{
              color: token.colorPrimaryButtonText,
              backgroundColor: token.colorPrimaryButton,
            }}
            onClick={() => {
              managerState.setKeyValue("activeEmail", null);
              managerState.openModal("addManagedUserModal");
            }}
          >
            + Add New Client
          </Button>
        </Space>
      </Row>
      <TableLevelClients />

      <ModalManagedUserData
        handleClose={() => managerState.closeModal("addManagedUserModal")}
        isLevelView={true}
        open={managerState.addManagedUserModal}
      />
    </SecurityGate>
  );
};

export default PageLevelClients;
