import styled from "styled-components";
import { Row } from "antd";

const StylesContainer = styled(Row)`
  background: #f5f9fc;
  border: 1px solid #bfc8ce;
  border-radius: 12px;
  cursor: pointer;
  margin: 13px 0 8px;
  min-width: 306px;
  width: 306px;
`;

export default StylesContainer;
