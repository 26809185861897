import { useContext } from "react";
import State from "../../../../context/user/State";
import SectionPerspectiveCoupleResults from "./components/SectionPerspectiveCoupleResults";
import SectionPerspectiveIndividualResults from "./components/SectionPerspectiveIndividualResults";
import SectionPrioritiesCoupleResults from "./components/SectionPrioritiesCoupleResults";
import SectionWorkspace from "./components/SectionWorkspace";

const PageDashboard = () => {
  const [userState] = useContext(State);

  return userState.viewPerspectiveIndividualResults ? (
    <SectionPerspectiveIndividualResults />
  ) : userState.viewPerspectiveCoupleResults ? (
    <SectionPerspectiveCoupleResults />
  ) : userState.viewPrioritiesCoupleResults ? (
    <SectionPrioritiesCoupleResults />
  ) : (
    <SectionWorkspace />
  );
};

export default PageDashboard;
