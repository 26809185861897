import money_primarily from "../images/money_primarily.png";
import more_money from "../images/more_money.png";
import less_money from "../images/less_money.png";
import describe_me from "../images/describe_me.png";
import unexpected_received from "../images/unexpected_received.png";

export const PERSPECTIVES_DEFAULT_STATE = {
  totalSteps: 5,
  moneyPrimarily: {
    imageSrc: money_primarily,
    stepIndex: 1,
    title: <div>Money is primarily a means for...</div>,
    values: [
      {
        label: "Enjoyment, fulfillment, bonding.",
        type: "Spending",
      },
      {
        label: "Generosity, impact, flourishing.",
        type: "Giving",
      },
      {
        label: "Potential, opportunity, preperation.",
        type: "Investing",
      },
      {
        label: "Responsibility, stability, readiness.",
        type: "Saving",
      },
    ],
  },
  moreMoney: {
    imageSrc: more_money,
    stepIndex: 2,
    title: (
      <div>
        The idea of <i>more</i> money feels...
      </div>
    ),
    values: [
      {
        label: "Empowering. I can make a difference with it!",
        type: "Giving",
      },
      {
        label: "Exhilarating. I can experience so with it!",
        type: "Spending",
      },
      {
        label: "Promising. I can grow it!",
        type: "Investing",
      },
      {
        label: "Calming. I can hold onto it!",
        type: "Saving",
      },
    ],
  },
  lessMoney: {
    imageSrc: less_money,
    stepIndex: 3,
    title: (
      <div>
        The idea of <i>less</i> money feels...
      </div>
    ),
    values: [
      {
        label: "Concerning. What about the future?",
        type: "Investing",
      },
      {
        label: "Discouraging. What if someone needs my help?",
        type: "Giving",
      },
      {
        label: "Alarming. What if there’s an emergency?",
        type: "Saving",
      },
      {
        label: "Deflating. What about my lifestyle?",
        type: "Spending",
      },
    ],
  },
  describeMe: {
    imageSrc: describe_me,
    stepIndex: 4,
    title: <div>Those closest to me would describe me as...</div>,
    values: [
      {
        label: "Vigilant, dependable, loyal.",
        type: "Saving",
      },
      {
        label: "Lively, present, adventurous.",
        type: "Spending",
      },
      {
        label: "Driven, visionary, ambitious.",
        type: "Investing",
      },
      {
        label: "Compassionate, supportive, sacrificial.",
        type: "Giving",
      },
    ],
  },
  unexpectedlyReceived: {
    imageSrc: unexpected_received,
    stepIndex: 5,
    title: (
      <div>
        If you unexpectedly received a significant inheritance from a relative,
        you would most want to...
      </div>
    ),
    values: [
      {
        label: "Spend it. There are a few things I’ve been waiting to upgrade!",
        type: "Spending",
      },
      {
        label: "Save it. My emergency fund could always be bigger!",
        type: "Saving",
      },
      {
        label: "Give it. Those around me need it more than I do!",
        type: "Giving",
      },
      {
        label: "Invest it. This will compound nicely over time!",
        type: "Investing",
      },
    ],
  },
};

export const LIST_PERSPECTIVES_POINTS = [5, 3, 1, 0];
