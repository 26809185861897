import { Form, Radio, Space } from "antd";
import TableAccount from "../TableAccount";
import TableIncome from "../TableIncome";
import TableExpense from "../TableExpense";

import { StyledInput } from "./styles";

import {
  cleanCurrencyValue,
  getCurrencyValue,
  selectValue,
} from "../../../../../../utils/helpers/general";

const TabGoalInfo = ({
  handleUserDataChange,
  handleViewTypeChange,
  newUserData,
  showBasicSection,
}) => (
  <>
    <Form.Item lable={"Plan Using"} name={"planType"}>
      <Radio.Group id={"planType"} onChange={handleViewTypeChange}>
        <Radio value={"basic"}>Basic</Radio>
        <Radio value={"advanced"}>Advanced</Radio>
      </Radio.Group>
    </Form.Item>
    {showBasicSection ? (
      <Space size={40}>
        <Form.Item label={"Savings to Date"} name={"totalSavings"}>
          <StyledInput
            id={"totalSavingsInput"}
            min={0}
            max={100000000}
            step={1000}
            formatter={getCurrencyValue}
            parser={cleanCurrencyValue}
            onClick={selectValue}
          />
        </Form.Item>
        <Form.Item label={"Annual Income"} name={"annualIncome"}>
          <StyledInput
            id={"annualIncomeInput"}
            min={0}
            max={10000000}
            step={1000}
            formatter={getCurrencyValue}
            parser={cleanCurrencyValue}
            onClick={selectValue}
          />
        </Form.Item>
        <Form.Item label={"Monthly Savings"} name={"savingsPerMonth"}>
          <StyledInput
            id="savingsPerMonthInput"
            min={0}
            max={10000000}
            step={1000}
            formatter={getCurrencyValue}
            parser={cleanCurrencyValue}
            onClick={selectValue}
          />
        </Form.Item>
      </Space>
    ) : (
      <Space direction="vertical" size={35} style={{ width: "100%" }}>
        <TableAccount
          newUserData={newUserData}
          handleUserDataChange={handleUserDataChange}
        />
        <TableIncome
          newUserData={newUserData}
          handleUserDataChange={handleUserDataChange}
        />
        <TableExpense
          newUserData={newUserData}
          handleUserDataChange={handleUserDataChange}
        />
      </Space>
    )}
  </>
);

export default TabGoalInfo;
