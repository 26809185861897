import { useLocation, useNavigate } from "react-router-dom";
import { ConfigProvider, Flex, theme } from "antd";

import { StyledMenu } from "./styles";

const MenuSidebar = ({ itemsLayoutVertical, routes }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { token } = theme.useToken();

  const getMenuItems = () =>
    routes
      ?.filter(it => it.text)
      ?.map(({ Icon, path, text, disabled }) => ({
        disabled,
        key: path,
        label: itemsLayoutVertical ? (
          <Flex align={"center"} gap={4} justify={"center"} vertical>
            <Icon />
            {text}
          </Flex>
        ) : (
          <Flex align={"center"} gap={14} justify={"start"}>
            <div style={{ textAlign: "center", width: 24 }}>
              <Icon />
            </div>
            {text}
          </Flex>
        ),
        onClick: () => !disabled && navigate(path),
      }));

  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            itemHoverBg: "transparent",
            itemActiveBg: "transparent",
          },
        },
      }}
    >
      <StyledMenu
        selectedKeys={[location.pathname]}
        items={getMenuItems()}
        token={token}
      />
    </ConfigProvider>
  );
};

export default MenuSidebar;
