import { useContext, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import dayjs from "dayjs";
import State from "../../../../context/user/State";
import { Button, Row, Space, theme, Tooltip, Typography } from "antd";
import DashboardCompare from "../../../../organisms/DashboardCompare";
import ModalConfirmSaveProposal from "../../components/ModalConfirmSaveProposal";
import ModalGoalSettings from "../../../../organisms/ModalGoalSettings";
import ModalProductDetails from "../../../../organisms/ModalProductDetails";
import ModalRelatedProducts from "../../../../organisms/ModalRelatedProducts";
import ProposalGoalDashboard from "../../components/ProposalGoalDashboard";
import CardSkeleton from "../../../../atoms/CardSkeleton";

import StylesContainer from "./styles";

import {
  DEFAULT_ADVISOR_FEE,
  DEFAULT_BIRTH_YEAR,
  DEFAULT_RETIREMENT_AGE,
  DEFAULT_START_WITHDRAWAL_YEAR,
  DEFAULT_WITHDRAWAL_LEVEL,
} from "../../../../utils/constants";
import { ONEASCENT_PORTFOLIO_X_RAY_LIST } from "../../../oneascent/constants";
import { LEVEL_SAMPLE_PROPOSAL_URL } from "../../../level/constants";

import {
  downloadProposal,
  getLiveAssessment,
} from "../../../../utils/requests/regularApp";
import {
  calculateWithdrawalLevelFromWithdrawalAmount,
  convertCurrentAgeRange,
  convertInvestmentDurationRange,
  getLastSavedProposalProps,
  getManuallyUpdatedOAGoalRecommendedProduct,
  isDemoProposalUser,
  isGrowthTypeRiskTolerance,
  isLockedByAdminView,
  isOrganizationLevel,
  isOrganizationOneascent,
} from "../../../../utils/helpers/specialized";
import {
  cleanPercentValue,
  getPercentValue,
} from "../../../../utils/helpers/general";
import { getRecommendedProductId } from "./helpers";

import { DownloadOutlined } from "@ant-design/icons";

const { Text } = Typography;

const PageProposalGoal = ({
  activeGoal,
  productsList,
  handleCloseGoal,
  setActiveGoal,
  setViewMode,
  viewMode,
}) => {
  const { token } = theme.useToken();
  const [userState, setUserState] = useContext(State);
  const [compareMode, setCompareMode] = useState(false);
  const [confirmSaveDialogOpened, setConfirmSaveDialogOpened] = useState(false);
  const [proposalData, setProposalData] = useState(null);
  const [proposalDirty, setProposalDirty] = useState(false);
  const [isCompareChartDirty, setIsCompareChartDirty] = useState(false);

  useEffect(() => {
    if (viewMode === "pdf" && proposalData && userState.userManagerData) {
      const interval = setInterval(() => {
        const barsChartSvg = localStorage.getItem("barsChartSvg");
        const growthChartSvg = localStorage.getItem("growthChartSvg");
        const pieChartSvg = localStorage.getItem("pieChartSvg");
        const pieChartWithLegendSvg = localStorage.getItem(
          "pieChartWithLegendSvg"
        );

        if (growthChartSvg && pieChartSvg && pieChartWithLegendSvg) {
          downloadProposal({
            barsChartSvg: barsChartSvg,
            guideInitialData: userState.getPreferenceValue("guideInitialData"),
            growthChartSvg: growthChartSvg,
            insufficientFundsStartingIn: userState.insufficientFundsStartingIn,
            pieChartSvg: pieChartSvg,
            pieChartWithLegendSvg: pieChartWithLegendSvg,
            proposalData: proposalData,
            userManagerData: userState.userManagerData,
            userName: `${userState.personalInfo.firstName} ${userState.personalInfo.lastName}`,
          });
          setViewMode("goals");
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [proposalData, viewMode]);

  useEffect(() => {
    if (!userState.loading) {
      if (!proposalData) {
        const prospectObjective =
          userState.getPreferenceValue("prospectObjective");
        const productMap = userState.getPreferenceValue("productMap");

        if (activeGoal && productMap) {
          const activeGoalLastSavedProps =
            productMap[activeGoal][productMap[activeGoal].length - 1];

          setProposalData({
            ...activeGoalLastSavedProps,
            birthYear: undefined,
            contributions:
              activeGoalLastSavedProps.contributions ??
              activeGoalLastSavedProps.savingsEachMonth * 12,
            lengthOfInvestment: undefined,
            retirementAge:
              userState.getPreferenceValue("guideInitialData")?.retirementAge ??
              DEFAULT_RETIREMENT_AGE,
            withdrawalLevel:
              activeGoalLastSavedProps.withdrawalLevel > 100
                ? Math.round(
                    (activeGoalLastSavedProps.withdrawalLevel /
                      activeGoalLastSavedProps.investmentAmount) *
                      100
                  )
                : activeGoalLastSavedProps.withdrawalLevel,
          });
        } else if (productMap) {
          const lastSavedProposalProps = getLastSavedProposalProps(productMap);
          const prospectObjective =
            userState.getPreferenceValue("prospectObjective");

          setProposalData({
            ...prospectObjective,
            birthYear: undefined,
            lengthOfInvestment: undefined,
            retirementAge:
              userState.getPreferenceValue("guideInitialData")?.retirementAge ??
              DEFAULT_RETIREMENT_AGE,
            timestamp: undefined,
            withdrawalLevel:
              lastSavedProposalProps.withdrawalLevel > 100
                ? Math.round(
                    (lastSavedProposalProps.withdrawalLevel /
                      lastSavedProposalProps.investmentAmount) *
                      100
                  )
                : lastSavedProposalProps.withdrawalLevel,
          });
        } else if (prospectObjective) {
          const investmentDuration = isNaN(prospectObjective.investmentDuration)
            ? convertInvestmentDurationRange(
                prospectObjective.investmentDuration
              )
            : +prospectObjective.investmentDuration;

          const currentAge =
            prospectObjective.currentAge ??
            convertCurrentAgeRange(prospectObjective.currentAgeRange);

          setProposalData({
            ...prospectObjective,
            advisorFee: prospectObjective.advisorFee ?? DEFAULT_ADVISOR_FEE,
            birthYear: currentAge
              ? undefined
              : prospectObjective.birthYear || DEFAULT_BIRTH_YEAR,
            currentAge,
            incomeChartType: isGrowthTypeRiskTolerance(
              prospectObjective.investmentObjective
            )
              ? "contributions"
              : "income",
            investmentDuration,
            lengthOfInvestment: undefined,
            retirementAge:
              userState.getPreferenceValue("guideInitialData")?.retirementAge ??
              DEFAULT_RETIREMENT_AGE,
            withdrawalLevel:
              prospectObjective.withdrawalLevel > 100
                ? calculateWithdrawalLevelFromWithdrawalAmount({
                    contributions: prospectObjective.contributions,
                    fee: cleanPercentValue(prospectObjective.advisorFee),
                    investmentAmount: prospectObjective.investmentAmount,
                    riskTolerance: prospectObjective.riskTolerance,
                    withdrawalAmount: prospectObjective.withdrawalLevel,
                    yearToStartWithdrawal:
                      prospectObjective.yearToStartWithdrawals ??
                      Math.round(investmentDuration / 2),
                  })
                : (prospectObjective.withdrawalLevel ??
                  DEFAULT_WITHDRAWAL_LEVEL),
            yearToStartWithdrawals:
              prospectObjective.yearToStartWithdrawals ||
              Math.round(investmentDuration / 2) ||
              DEFAULT_START_WITHDRAWAL_YEAR,
          });
        }
      }
    }
  }, [userState]);

  useEffect(() => {
    if (proposalData) {
      const recommendedProductId =
        userState.selectedProduct ??
        proposalData.recommendedProductId ??
        proposalData.productId ??
        getRecommendedProductId({
          organization: userState.organization?.name ?? userState.orgName,
          productsList,
          proposalData,
        });

      if (recommendedProductId) {
        setUserState(lastState => ({
          ...lastState,
          selectedProduct: recommendedProductId,
          compareProducts: [recommendedProductId],
        }));
      }
    }
  }, [productsList, proposalData, viewMode]);

  useEffect(() => {
    if (!activeGoal) {
      setProposalDirty(true);
    }
  }, [activeGoal]);

  const handleCardClick = cardId => {
    userState.setKeyValue("productDetailsId", cardId);
    userState.openModal("productDetailsModal");
  };

  const handleProposalDataChange = useDebouncedCallback(
    (key, value, skipDirty) => {
      if (!isLockedByAdminView(userState.showWarning)) {
        !skipDirty && setProposalDirty(true);

        setProposalData(lastState => ({
          ...lastState,
          [key]: value,
        }));
      }
    },
    500
  );

  const handleSaveProposal = () => {
    userState.showLoader();

    getLiveAssessment({
      objective: {
        ...proposalData,
        investmentDuration:
          proposalData?.investmentDuration ?? proposalData?.lengthOfInvestment,
        productId: userState.selectedProduct,
        timestamp: undefined,
      },
      organization: userState.organization?.name?.toLowerCase(),
      productId: userState.selectedProduct,
      saveAction: activeGoal ? "PUT" : "POST",
      saveId: activeGoal,
      activeGoal,
    })
      .then(() => {
        setProposalDirty(false);
        userState.showSuccess("Proposal saved");
        setConfirmSaveDialogOpened(false);
        setTimeout(() => {
          userState.setUserData().then(data => {
            if (activeGoal) {
              //update existed active goal
              setProposalData(
                data.preferences.valueMap.productMap[activeGoal][
                  data.preferences.valueMap.productMap[activeGoal].length - 1
                ]
              );
            } else {
              //set new created goal ID as active goal
              const createdGoalId = Object.keys(
                data.preferences.valueMap.productMap
              )[Object.keys(data.preferences.valueMap.productMap).length - 1];

              setActiveGoal(createdGoalId);
              setProposalData(
                data.preferences.valueMap.productMap[createdGoalId][
                  data.preferences.valueMap.productMap[createdGoalId].length - 1
                ]
              );
            }
            userState.hideLoader();
          });
        }, 1000);
      })
      .catch(({ response }) => {
        userState.hideLoader();
        userState.showError(response?.data?.error ?? response?.data?.message);
      });
  };

  const handleUpdateGoal = updatedProposalData => {
    setProposalData(updatedProposalData);
    userState.closeModal("goalSettingsModal");
    userState.showSuccess("Goal settings updated");
    setProposalDirty(true);

    const recommendedProduct = getManuallyUpdatedOAGoalRecommendedProduct({
      customRiskTolerance: updatedProposalData.riskTolerance,
      currentSelectedProduct: productsList.find(
        it => it._id === userState.selectedProduct
      ),
      productsList,
    });

    if (recommendedProduct) {
      setUserState(lastState => ({
        ...lastState,
        compareProducts: [recommendedProduct._id],
        selectedProduct: recommendedProduct._id,
      }));
    }
  };

  const defaultListCardAction = [
    {
      buttonActiveCopy: "Added to Proposal",
      buttonCopy: "Add to Proposal",
      key: "addToProposal",
      onSelect: product => {
        userState.closeModal("relatedProductsModal");
        const newProductData = productsList.find(it => it._id === product._id);

        if (newProductData) {
          handleProposalDataChange(
            "riskTolerance",
            newProductData.riskTolerance
          );
        }

        setUserState(lastState => ({
          ...lastState,
          compareProducts: [product._id],
          selectedProduct: product._id,
        }));
      },
    },
  ];

  const compareListCardAction = [
    {
      buttonActiveCopy: "Selected to Compare",
      buttonCopy: "Add to Compare",
      key: "addToCompare",
      onSelect: product => {
        setUserState(lastState => ({
          ...lastState,
          compareProducts: lastState.compareProducts.includes(product._id)
            ? lastState.compareProducts.filter(id => id !== product._id)
            : [...lastState.compareProducts, product._id],
        }));

        setIsCompareChartDirty(true);
        userState.closeModal("relatedProductsModal");
      },
    },
  ];

  const handleDownloadProposalClick = () => {
    if (
      isOrganizationLevel(userState.organization?.name ?? userState.orgName)
    ) {
      window.open(LEVEL_SAMPLE_PROPOSAL_URL, "_blank");
      return;
    }

    setUserState(lastState => ({
      ...lastState,
      activeGoal,
      productDetailsId: userState.selectedProduct,
    }));

    localStorage.removeItem("barsChartSvg");
    localStorage.removeItem("growthChartSvg");
    localStorage.removeItem("pieChartSvg");
    localStorage.removeItem("pieChartWithLegendSvg");

    setViewMode("pdf");
    userState.openModal("productDetailsModal");
  };

  return (
    <StylesContainer>
      <CardSkeleton loading={userState.loading}>
        <Row
          justify={"space-between"}
          style={{
            paddingBottom: 15,
            borderBottom: `1.5px solid ${token.colorPrimary}`,
          }}
        >
          <Space direction={"vertical"} size={0}>
            <Text style={{ color: token.colorGrey1 }}>Goal</Text>
            <Text
              style={{
                fontSize: 24,
                color: token.colorNavy,
                textTransform: "capitalize",
              }}
              strong
            >
              {proposalData?.investmentObjective}
            </Text>
          </Space>
          {compareMode ? (
            <Space align={"end"}>
              <Button
                onClick={() => setCompareMode(false)}
                shape={"round"}
                style={{
                  background: token.tenantColorPrimary,
                  borderColor: "transparent",
                  color: "#FFFFFF",
                  width: 178,
                }}
              >
                Exit Compare Mode
              </Button>
            </Space>
          ) : (
            !isDemoProposalUser(userState._id) && (
              <Space align={"end"} size={22} wrap>
                {!proposalDirty && proposalData?.timestamp ? (
                  <>
                    <Space
                      direction={"vertical"}
                      size={0}
                      style={{ textAlign: "right" }}
                    >
                      <Text style={{ fontSize: 12, color: token.colorGrey1 }}>
                        Created
                      </Text>
                      <Text style={{ fontSize: 12, color: token.colorBlack }}>
                        {dayjs(proposalData.timestamp).format(
                          "MM/DD/YYYY h:mma"
                        )}
                      </Text>
                    </Space>
                    <Button
                      icon={<DownloadOutlined />}
                      onClick={handleDownloadProposalClick}
                      shape={"round"}
                      style={{
                        background: token.tenantColorPrimary,
                        color: "#FFFFFF",
                        borderColor: "transparent",
                      }}
                    >
                      Download Proposal
                    </Button>
                    <Button
                      onClick={handleCloseGoal}
                      shape={"round"}
                      type={"primary"}
                    >
                      Close
                    </Button>
                  </>
                ) : (
                  <>
                    <Tooltip title={"Please save the proposal before closing"}>
                      <Button
                        onClick={handleCloseGoal}
                        shape={"round"}
                        type={"text"}
                        disabled={true}
                      >
                        Close
                      </Button>
                    </Tooltip>
                    <Button
                      disabled={isLockedByAdminView({
                        managerAccess: userState.managerAccess,
                      })}
                      onClick={() => setConfirmSaveDialogOpened(true)}
                      shape={"round"}
                      style={{
                        width: 156,
                        background: token.tenantColorPrimary,
                        color: "#FFFFFF",
                        borderColor: "transparent",
                      }}
                    >
                      Save Proposal
                    </Button>
                  </>
                )}
              </Space>
            )
          )}
          {isDemoProposalUser(userState._id) &&
            isOrganizationLevel(
              userState.organization?.name ?? userState.orgName
            ) && (
              <Button
                icon={<DownloadOutlined />}
                onClick={() => window.open(LEVEL_SAMPLE_PROPOSAL_URL)}
                shape={"round"}
                style={{
                  background: token.tenantColorPrimary,
                  color: "#FFFFFF",
                  borderColor: "transparent",
                }}
              >
                Download Proposal
              </Button>
            )}
        </Row>
        {compareMode ? (
          <DashboardCompare
            closeCompareMode={() => setCompareMode(false)}
            handleCardClick={handleCardClick}
            isCompareChartDirty={isCompareChartDirty}
            openProductsListModal={() =>
              userState.openModal("relatedProductsModal")
            }
            productsList={productsList}
            proposalData={proposalData}
            setIsCompareChartDirty={setIsCompareChartDirty}
            setState={setUserState}
            showError={userState.showError}
            showSecondaryButton={true}
            state={userState}
          />
        ) : (
          <ProposalGoalDashboard
            activeGoal={activeGoal}
            handleCardClick={handleCardClick}
            handleProposalDataChange={handleProposalDataChange}
            openCompareMode={() => setCompareMode(true)}
            productsList={productsList}
            proposalData={proposalData}
            viewMode={viewMode}
          />
        )}

        <ModalConfirmSaveProposal
          handleClose={() => setConfirmSaveDialogOpened(false)}
          handleSaveProposal={handleSaveProposal}
          isFirstSave={
            !(userState.getPreferenceValue("productMap") ?? [])[activeGoal]
          }
          open={confirmSaveDialogOpened}
        />
        <ModalProductDetails
          handleClose={() => userState.closeModal("productDetailsModal")}
          hideMcSettingsToggler={true}
          investmentAssumptions={{}}
          open={userState.productDetailsModal}
          organization={userState.organization?.name}
          product={{
            ...productsList.find(it => it._id === userState.productDetailsId),
            ...proposalData,
            advisorFee: getPercentValue(proposalData?.advisorFee),
            investmentDuration:
              proposalData?.investmentDuration ??
              proposalData?.lengthOfInvestment,
            withdrawalLevel: getPercentValue(
              proposalData?.withdrawalLevel ?? 0
            ),
          }}
          productsList={productsList}
          xRayList={
            isOrganizationOneascent(userState.organization?.name) &&
            ONEASCENT_PORTFOLIO_X_RAY_LIST
          }
          viewMode={viewMode}
        />
      </CardSkeleton>
      <ModalGoalSettings
        handleClose={() => userState.closeModal("goalSettingsModal")}
        handleUpdateGoal={handleUpdateGoal}
        loading={userState.loading}
        open={userState.goalSettingsModal}
        organization={userState.organization?.name}
        proposalData={proposalData}
      />
      <ModalRelatedProducts
        cardActions={
          compareMode ? compareListCardAction : defaultListCardAction
        }
        compareProducts={userState.compareProducts}
        favoriteFinancialProducts={userState.favoriteFinancialProducts}
        handleCardClick={handleCardClick}
        handleClose={() => userState.closeModal("relatedProductsModal")}
        hideFavoriteProducts={isOrganizationOneascent(
          userState.organization?.name
        )}
        open={userState.relatedProductsModal}
        productsList={productsList}
        selectedProduct={userState.selectedProduct}
      />
    </StylesContainer>
  );
};

export default PageProposalGoal;
