import styled from "styled-components";
import { Button, Space } from "antd";

const StylesContainer = styled(Space)`
  gap: 36px;
  width: 100%;
  max-width: 716px;
  background: #ffffff;
  padding: 23px 45px 15px 29px;
  border-radius: 8px;
  box-shadow: 0 0 16px #0000000d;
`;

export const StyledButton = styled(Button)`
  color: #4e96d1;
  border-color: #4e96d1;
  background: #ffffff;
`;

export default StylesContainer;
