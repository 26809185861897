import { Flex } from "antd";
import Chart from "../Chart";

import { LIST_TENDENCY } from "../../../../../../constants";

const TabBlindSpots = ({ coupleCalculations }) => {
  const tendencyType = coupleCalculations.find(it => it.total < 50)?.type;

  const tendencyData = LIST_TENDENCY.find(it => it.type === tendencyType);

  return (
    <Flex gap={10}>
      <Flex className={"copy-container"} vertical>
        <h4 className={"title"}>
          {tendencyType ?? "No Personality of this type"}
        </h4>
        <p
          dangerouslySetInnerHTML={{
            __html: tendencyData?.descriptionBlindSpots,
          }}
        />
      </Flex>

      <Chart
        labelAccentBackground={tendencyData?.labelBackgroundColor}
        labelAccentValue={tendencyData?.value}
      />
    </Flex>
  );
};

export default TabBlindSpots;
