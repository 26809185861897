import { useEffect } from "react";
import { Button, Flex, Form, Input } from "antd";

import StyledModal from "./styles";

import Close from "../../../../icons/Close";

const ModalFeaturedVideo = ({ link, loading, open, onCancel, onSuccess }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (open) {
      form.setFieldValue("link", link);
    }
  }, [open]);

  return (
    <StyledModal
      className={"featured-video-modal"}
      title={"Featured Video"}
      open={open}
      onCancel={onCancel}
      closeIcon={<Close />}
      footer={
        <Flex gap={6}>
          <Button
            disabled={loading}
            htmlType={"submit"}
            form={"formFeaturedVideo"}
            shape={"round"}
            type={"primary"}
          >
            Update
          </Button>
          <Button type="text" shape="round" onClick={onCancel}>
            Cancel
          </Button>
        </Flex>
      }
    >
      <Form
        form={form}
        id={"formFeaturedVideo"}
        layout={"vertical"}
        onFinish={onSuccess}
      >
        <Form.Item label={"Embed URL"} name={"link"}>
          <Input className={"input-custom"} />
        </Form.Item>
      </Form>
    </StyledModal>
  );
};

export default ModalFeaturedVideo;
