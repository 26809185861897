import { useContext, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import State from "../../context/user/State";
import { Flex, Layout, Row, theme } from "antd";
import SectionAskAdvice from "../../organisms/SectionAskAdvice";
import MenuGoalsUser from "../../ecosystems/goals/components/MenuGoalsUser";
import MenuSidebar from "../../molecules/MenuSidebar";
import ModalContactUs from "../../organisms/ModalContactUs";
import ModalGoalsUserSettings from "../../organisms/ModalGoalsUserSettings";
import Header from "./components/Header";

import StyledLayout from "./styles";
import "./index.css";

import {
  getOrganizationManagerData,
  getUserManagerData,
} from "../../utils/requests/manager";

const { Content, Sider, Footer } = Layout;

const LayoutProposal = ({ routes, children }) => {
  const { token } = theme.useToken();
  const [userState, setUserState] = useContext(State);
  const isSmallScreen = useMediaQuery({ maxWidth: token.screenLGMin });

  useEffect(() => {
    getUserManagerData()
      .then(data => {
        userState.setKeyValue("userManagerData", data);
      })
      .catch(() => {
        getOrganizationManagerData()
          .then(data => {
            userState.setKeyValue("userManagerData", data);
          })
          .catch(console.log);
      });
  }, []);

  const handleButtonBackToDashboard = () => {
    userState.setKeyValue("viewPerspectiveIndividualResults", false);
    userState.setKeyValue("viewPerspectiveCoupleResults", false);
    userState.setKeyValue("viewPrioritiesCoupleResults", false);
    userState.setKeyValue("showButtonBackToDashboard", false);
  };

  return (
    <StyledLayout hasSider token={token}>
      {!isSmallScreen && (
        <Sider width={80}>
          <MenuSidebar itemsLayoutVertical routes={routes} />
          <MenuGoalsUser />
        </Sider>
      )}
      <Layout>
        <Header
          handleButtonBackToDashboard={handleButtonBackToDashboard}
          openModal={userState.openModal}
          organization={userState.organization?.name ?? userState.orgName}
          showButtonBackToDashboard={userState.showButtonBackToDashboard}
        />
        <Row
          justify={"center"}
          style={{
            height: "100%",
          }}
        >
          <Content
            className="tab-background"
            style={{
              maxWidth: 1550,
              overflow: "auto",
            }}
          >
            {children}
          </Content>
        </Row>
      </Layout>
      {isSmallScreen && (
        <Footer>
          <Flex align={"center"} justify={"space-between"}>
            <MenuSidebar itemsLayoutVertical routes={routes} />
            <MenuGoalsUser />
          </Flex>
        </Footer>
      )}

      <SectionAskAdvice setState={setUserState} state={userState} />
      <ModalContactUs />
      <ModalGoalsUserSettings />
    </StyledLayout>
  );
};

export default LayoutProposal;
