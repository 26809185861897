import { useContext, useEffect } from "react";
import State from "../../../../../../context/manager/State";
import { Button, Flex, Form, InputNumber } from "antd";
import CardChartPie from "../CardChartPie";

import StylesContainer from "./styles";

import { PORTFOLIO_LIST, PRODUCT_LIST } from "../../constants";

import {
  cleanCurrencyValue,
  getCurrencyValue,
  selectValue,
} from "../../../../../halo/utils/feature";

const SectionCheckout = ({
  handleComplete,
  handleGoBack,
  handleStartOver,
  proposalData,
}) => {
  const [managerState] = useContext(State);
  const [form] = Form.useForm();

  const relatedFinancialProduct = managerState.productsList.find(it =>
    it.name.includes(proposalData.product)
  );

  const selectedProductDataAllocation =
    proposalData.portfolio &&
    PRODUCT_LIST.find(it => it.label === proposalData.product).allocation[
      proposalData.portfolio
    ];

  useEffect(() => {
    form.setFieldsValue(proposalData);
  }, [proposalData]);

  const handleButtonComplete = () => {
    handleComplete({
      ...form.getFieldsValue(),
      productId: relatedFinancialProduct._id,
    });
  };

  return (
    <StylesContainer align={"center"} gap={60} vertical>
      <Flex align={"center"} gap={90}>
        <Form form={form} id={"astorQuestionnaireForm"} layout={"vertical"}>
          <Form.Item
            label={"How much are you investing initially?"}
            name={"investmentAmount"}
          >
            <InputNumber
              formatter={getCurrencyValue}
              max={100000000}
              min={0}
              onClick={selectValue}
              parser={cleanCurrencyValue}
              size={"large"}
              step={1000}
            />
          </Form.Item>
          <Form.Item
            label={"How long will you hold this investment?"}
            name={"investmentDuration"}
          >
            <InputNumber max={80} min={1} onClick={selectValue} />
          </Form.Item>
        </Form>
        <div style={{ position: "relative" }}>
          <CardChartPie
            containerHeight={350}
            containerWidth={300}
            pieSize={200}
            readonly
            series={
              selectedProductDataAllocation
                ? selectedProductDataAllocation.find(
                    it => it.value === proposalData.allocation
                  )?.series
                : [
                    {
                      color: "#0B2A46",
                      name: "",
                      y: 100,
                    },
                  ]
            }
            size={"large"}
            title={
              proposalData.portfolio
                ? PORTFOLIO_LIST.find(it => it.value === proposalData.portfolio)
                    ?.label
                : ""
            }
          />
          <Flex className={"card-related-financial-product"} vertical>
            <span className={"label"}>Astor</span>
            <h2 className={"title"}>{relatedFinancialProduct?.name}</h2>
            <p className={"description"}>
              {relatedFinancialProduct?.riskTolerance}
            </p>
          </Flex>
        </div>
      </Flex>
      <Flex align={"center"} gap={12} style={{ width: 292 }} vertical>
        <Button
          block
          onClick={handleButtonComplete}
          shape={"round"}
          style={{ background: "#1971B9", color: "#fff", width: 228 }}
          type={"primary"}
        >
          Complete
        </Button>
        <Flex align={"center"} justify={"center"}>
          <Button
            onClick={handleGoBack}
            shape={"round"}
            size={"small"}
            style={{ color: "#32A2DE" }}
            type={"text"}
          >
            Go back
          </Button>
          <div
            style={{
              background: "#32a2de",
              height: 20,
              width: 1,
            }}
          />
          <Button
            onClick={handleStartOver}
            shape={"round"}
            size={"small"}
            style={{ color: "#32A2DE" }}
            type={"text"}
          >
            Start over
          </Button>
        </Flex>
      </Flex>
    </StylesContainer>
  );
};

export default SectionCheckout;
