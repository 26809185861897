import styled from "styled-components";
import { Modal } from "antd";

const StyledModal = styled(Modal)`
  &.ant-modal {
    top: 20px;

    .ant-modal-content {
      padding: 33px 30px;
      color: #000;
    }

    .ant-modal-header {
      margin-bottom: 32px;
    }

    .ant-modal-footer {
      margin: 0;
    }

    .ant-modal-title {
      color: #000;
      font-family: Roboto, sans-serif;
      font-size: 26px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }

    .ant-modal-body {
      font-family: Roboto, sans-serif;
    }
  }
`;

export default StyledModal;
