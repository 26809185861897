import LogoOneascent from "../images/logo.svg";

export default {
  chartColors: ["#4E96D1", "#1A2849", "#64C981", "#CCB681", "#00BDC4"],
  colorPrimary: "#4897D1",
  colorPrimaryButtonText: "#FFFFFF",
  goalsHeaderBackground: "#eef1f3",
  logoSrc: LogoOneascent,
  modalFinancialProductDetailsHeaderBackground: "#E7EBEE",
  pageLoginButtonSubmitColor: "#192849",
  sidebarBackground: "linear-gradient(180deg, #162A4E 0%, #216A9C 100%)",
  sidebarIconColor: "#fff",
  sidebarIconColorActive: "#74C2E6",
  sidebarMenuItemHoverBackground: "#152A4E",
  tenantColorPrimary: "#4897D1",
};
