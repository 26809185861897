import styled from "styled-components";
import { Button, Typography } from "antd";

export const StyledTitle = styled("h3")`
  line-height: 28px;
  font-size: 27px;
  color: #1b5568;
  margin: 50px 0 10px;
`;

export const Description = styled("p")`
  line-height: 23px;
  font-size: 16px;
  font-weight: 300;
  margin: 0;
  color: #1b5568;
`;

export const SubTitle = styled("h1")`
  font-size: 42px;
  font-weight: bold;
  margin: 100px 0 28px;
  color: #1b5568;
`;

export const SubDescription = styled(Typography.Paragraph)`
  font-size: 22px;
  font-weight: 300;
  margin: 0;
  color: #1b5568;
`;

export const StyledButton = styled(Button)`
  width: 146px;
`;
