import { useEffect, useState } from "react";
import dayjs from "dayjs";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Button, Row, Space, theme, Typography } from "antd";
import ArchiveGoalDialog from "./components/ArchiveGoalDialog";
import RestoreGoalDialog from "./components/RestoreGoalDialog";
import ChartIncomeWithTypes from "../../../../molecules/ChartIncomeWithTypes";
import CardProduct from "../../../../molecules/CardProduct";
import MenuActions from "../../../../atoms/MenuActions";
import ChartSkeleton from "../../../../atoms/ChartSkeleton";

import { ArchivedGoalBanner, StylesContainer } from "./styles";

import { getLiveAssessment } from "../../../../utils/requests/regularApp";
import { getGrowthChartConfig } from "../../../../utils/charts";
import { getPercentValue } from "../../../../utils/helpers/general";
import { isOrganizationRivershares } from "../../../../utils/helpers/specialized";

import { ReactComponent as ActionsButton } from "../../../../icons/actions_menu.svg";
import { DownloadOutlined } from "@ant-design/icons";

const ProposalGoalPreview = ({
  archivedGoals,
  goalsList,
  handleArchiveGoal,
  handleCardClick,
  handleCopyGoal,
  handleDownloadProposalClick,
  handleRestoreGoal,
  handleViewGoal,
  organization,
  productData,
  productsList,
  showError,
}) => {
  const { token } = theme.useToken();
  const [loading, setLoading] = useState(false);
  const [growthChartSeries, setGrowthChartSeries] = useState([]);
  const [liveAssessment, setLiveAssessment] = useState();
  const [archiveGoalDialogOpened, setArchiveGoalDialogOpened] = useState(false);
  const [restoreGoalDialogOpened, setRestoreGoalDialogOpened] = useState(false);
  const [archivedGoalData, setArchivedGoalData] = useState();

  useEffect(() => {
    if (archivedGoals && archivedGoals[productData.goalId]) {
      setArchivedGoalData(archivedGoals[productData.goalId]);
    }
  }, [archivedGoals]);

  useEffect(() => {
    setLoading(true);

    const requestBody = {
      organization,
      productId: productData.productId ?? productData._id,
      objective: {
        ...productData,
        birthYear: undefined,
        currentAgeRange: undefined,
        lengthOfInvestment: undefined,
        withdrawalLevel: getPercentValue(productData.withdrawalLevel),
      },
    };

    getLiveAssessment(requestBody)
      .then(data => {
        setLiveAssessment({
          ...data,
          productId: productData._id,
        });
        setGrowthChartSeries(getGrowthChartSeries(data));
        setLoading(false);
      })
      .catch(({ response }) => {
        showError(response?.data?.error ?? response?.data?.message);
      });
  }, []);

  const getGrowthChartSeries = calculationsData => {
    const series = [];

    calculationsData?.grandTotalColumn &&
      series.push({
        name: "Calculated progress",
        lineWidth: 2,
        color: "#89D2DC",
        zoneAxis: "x",
        marker: {
          enabled: false,
        },
        data: calculationsData.grandTotalColumn,
      });

    return series;
  };

  const getProductCard = () => {
    const product = productsList.find(it => it._id === productData.productId);

    return (
      <CardProduct
        handleCardClick={handleCardClick}
        labels={[product?.riskTolerance, product?.allocationType]}
        maxWidth={"375px"}
        product={product}
        width={"375px"}
      />
    );
  };

  const getActions = () =>
    archivedGoalData
      ? [
          {
            key: "1",
            label: (
              <div onClick={() => setRestoreGoalDialogOpened(true)}>
                Restore
              </div>
            ),
          },
        ]
      : [
          {
            key: "1",
            label: (
              <div onClick={() => handleCopyGoal(productData.goalId)}>
                Copy Goal
              </div>
            ),
          },
          {
            key: "2",
            label: (
              <div onClick={() => setArchiveGoalDialogOpened(true)}>
                Archive Goal
              </div>
            ),
            disabled: goalsList && Object.keys(goalsList).length <= 1,
          },
        ];

  return (
    <StylesContainer className={"proposal-goal-preview-container"}>
      {archivedGoalData && (
        <ArchivedGoalBanner
          align={"middle"}
          justify={"center"}
          style={{ background: token.colorGrey3 }}
        >
          <Typography.Text style={{ fontSize: 12 }}>
            <b>ARCHIVED</b>{" "}
            {dayjs(archivedGoalData.timestamp).format("MM/DD/YYYY h:mma")}
          </Typography.Text>
        </ArchivedGoalBanner>
      )}
      <Row
        justify={"space-between"}
        style={{ marginTop: archivedGoalData && 15, marginBottom: 15 }}
      >
        <Space
          className={"risk-tolerance-container"}
          direction={"vertical"}
          size={0}
        >
          <Typography.Text style={{ color: token.colorGrey1 }}>
            Investment Objective
          </Typography.Text>
          <Typography.Text
            strong
            style={{ lineHeight: 1, fontSize: 22, color: token.colorNavy }}
          >
            {!isOrganizationRivershares(organization) && "Drive "}
            <span style={{ textTransform: "capitalize" }}>
              {productData.investmentObjective}
            </span>
          </Typography.Text>
        </Space>
        <Space className={"actions-container"}>
          <Space direction={"vertical"} align={"end"} size={0}>
            <Typography.Text style={{ fontSize: 12, color: token.colorGrey1 }}>
              Created
            </Typography.Text>
            <Typography.Text style={{ lineHeight: 1, fontSize: 12 }}>
              {dayjs(productData?.timestamp).format("MM/DD/YYYY h:mma")}
            </Typography.Text>
          </Space>
          <MenuActions items={getActions()}>
            <Button
              icon={<ActionsButton />}
              shape={"circle"}
              size={"small"}
              style={{ background: "#D9F3F7", width: 32 }}
              type={"text"}
            />
          </MenuActions>
        </Space>
      </Row>
      <ChartSkeleton
        loading={loading}
        height={productData?.contributions === 0 ? 271 : 147}
      >
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            ...getGrowthChartConfig({
              birthYear:
                productData.currentAge &&
                dayjs().format("YYYY") - productData.currentAge,
              height: productData?.contributions === 0 ? 271 : 147,
            }),
            series: growthChartSeries,
          }}
        />
      </ChartSkeleton>
      <ChartIncomeWithTypes
        loading={loading}
        productsList={productsList}
        productsLiveAssessment={liveAssessment ? [liveAssessment] : []}
        productData={productData}
      />
      <Typography.Paragraph style={{ marginBottom: 12 }}>
        Selected product
      </Typography.Paragraph>
      {getProductCard()}
      <Space size={16} wrap style={{ marginTop: 22 }}>
        <Button
          disabled={archivedGoalData}
          icon={<DownloadOutlined />}
          onClick={() =>
            handleDownloadProposalClick(
              productData.goalId,
              productData.productId
            )
          }
          shape="round"
          style={{
            width: 189,
            background: token.tenantColorPrimary,
            color: "#ffffff",
            borderColor: "transparent",
          }}
        >
          Download Proposal
        </Button>
        <Button
          disabled={archivedGoalData}
          onClick={() => handleViewGoal(productData.goalId)}
          shape={"round"}
          style={{ width: 76 }}
          type={"primary"}
        >
          View
        </Button>
      </Space>
      <ArchiveGoalDialog
        onCancel={() => setArchiveGoalDialogOpened(false)}
        onConfirm={() => {
          setArchiveGoalDialogOpened(false);
          handleArchiveGoal(productData.goalId);
        }}
        open={archiveGoalDialogOpened}
      />
      <RestoreGoalDialog
        onCancel={() => setRestoreGoalDialogOpened(false)}
        onConfirm={() => {
          setRestoreGoalDialogOpened(false);
          handleRestoreGoal(productData.goalId);
        }}
        open={restoreGoalDialogOpened}
      />
    </StylesContainer>
  );
};

export default ProposalGoalPreview;
