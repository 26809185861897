import { Button, Flex, Space } from "antd";
import CardProduct from "../../../../../../molecules/CardProduct";

const SectionExploreGrowthTypes = ({
  handleCardClick,
  setCompareMode,
  state,
}) => (
  <Flex gap={14} style={{ marginTop: 30 }} vertical>
    <Flex align={"center"} gap={43}>
      <span style={{ fontSize: 18, color: "#405377" }}>
        Explore Growth Types
      </span>
      <Space size={17}>
        <Button
          type={"primary"}
          shape={"round"}
          size={"small"}
          onClick={() => setCompareMode(true)}
        >
          Compare Growth Types
        </Button>
        <Button
          type={"primary"}
          shape={"round"}
          size={"small"}
          onClick={() => state.openModal("otherGrowthTypesModal")}
        >
          Other Growth Types
        </Button>
      </Space>
    </Flex>
    <CardProduct
      actions={[
        {
          buttonCopy: "View Details",
          buttonWidth: 165,
          key: "viewDetails",
          onSelect: product => handleCardClick(product?._id),
        },
      ]}
      handleCardClick={handleCardClick}
      labels={[
        state.productsList?.find(it => it._id === state.selectedProduct)
          ?.investmentPhilosophy,
      ]}
      product={state.productsList?.find(it => it._id === state.selectedProduct)}
      showChart
      showObjective
      maxWidth={"567px"}
      width={"567px"}
    />
  </Flex>
);

export default SectionExploreGrowthTypes;
