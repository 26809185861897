import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import {
  Button,
  Col,
  Flex,
  Form,
  InputNumber,
  Row,
  Select,
  Switch,
  Tabs,
  theme,
  Typography,
} from "antd";

import StyledModal, { StyledButton } from "./styles";

import { GOALS_RISK_TYPES } from "../../utils/constants";
import {
  ONEASCENT_GOAL_TYPES,
  ONEASCENT_RISK_TYPES,
} from "../../ecosystems/oneascent/constants";
import {
  RIVERSHARES_GOAL_TYPES,
  RIVERSHARES_RISK_TYPES,
} from "../../ecosystems/rivershares/constants";
import { ASTOR_RISK_TYPES } from "../../ecosystems/astor/constants";

import {
  cleanCurrencyValue,
  cleanPercentValue,
  getCurrencyValue,
  getPercentValue,
  selectValue,
} from "../../utils/helpers/general";
import {
  isGrowthTypeRiskTolerance,
  isOrganizationAstor,
  isOrganizationOneascent,
  isOrganizationRivershares,
} from "../../utils/helpers/specialized";

import Close from "../../icons/CloseOutlined";
import OpenInNew from "../../icons/OpenInNew";
import { ReactComponent as AggressiveIcon } from "./images/aggressive_icon.svg";
import { ReactComponent as ModerateIcon } from "./images/moderate_icon.svg";
import { ReactComponent as ConservativeIcon } from "./images/conservative_icon.svg";
import { ReactComponent as ActiveIcon } from "./images/active_icon.svg";

const ModalGoalSettings = ({
  handleClose,
  handleUpdateGoal,
  loading,
  open,
  organization,
  proposalData,
}) => {
  const { token } = theme.useToken();
  const [form] = Form.useForm();
  const isMobile = useMediaQuery({ maxWidth: token.screenSM });
  const [annuities_isOn, setAnnuities_isOn] = useState();
  const [otherIncome_isOn, setOtherIncome_isOn] = useState();
  const [socialSecurity_isOn, setSocialSecurity_isOn] = useState();
  const [riskTolerance, setRiskTolerance] = useState();
  const [investmentObjective, setInvestmentObjective] = useState();

  useEffect(() => {
    if (open && proposalData) {
      form.setFieldsValue({
        ...proposalData,
        annuities_isOn: !!proposalData.annuities_isOn,
        contributions: proposalData.contributions / 12,
        otherIncome_isOn: !!proposalData.otherIncome_isOn,
        socialSecurity_isOn: !!proposalData.socialSecurity_isOn,
      });
      setAnnuities_isOn(!!proposalData.annuities_isOn);
      setOtherIncome_isOn(!!proposalData.otherIncome_isOn);
      setSocialSecurity_isOn(!!proposalData.socialSecurity_isOn);
      setRiskTolerance(proposalData.riskTolerance);
      setInvestmentObjective(proposalData.investmentObjective);
    }
  }, [open]);

  const getInvestmentObjectiveOptions = () => {
    if (isOrganizationRivershares(organization)) {
      return RIVERSHARES_GOAL_TYPES.map(({ value, label }) => ({
        value,
        label: `Drive ${label}`,
      }));
    } else {
      return ONEASCENT_GOAL_TYPES.map(({ value, label }) => ({
        value,
        label: `Drive ${label}`,
      }));
    }
  };

  const onFinish = updatedValues => {
    if (updatedValues.contributions) {
      updatedValues.contributions = updatedValues.contributions * 12;
    }

    handleUpdateGoal({
      ...proposalData,
      ...updatedValues,
    });
  };

  const onValuesChange = (value, values) => {
    setAnnuities_isOn(!!values.annuities_isOn);
    setOtherIncome_isOn(!!values.otherIncome_isOn);
    setSocialSecurity_isOn(!!values.socialSecurity_isOn);
    setInvestmentObjective(values.investmentObjective);
  };

  const handleMobileRiskToleranceButtonClick = selectedRiskTolerance => {
    form.setFieldValue("riskTolerance", selectedRiskTolerance);
    setRiskTolerance(selectedRiskTolerance);
  };

  const getOptions = () => {
    if (isOrganizationRivershares(organization)) return RIVERSHARES_RISK_TYPES;
    else if (isOrganizationOneascent(organization)) return ONEASCENT_RISK_TYPES;
    else if (isOrganizationAstor(organization)) return ASTOR_RISK_TYPES;
    else return GOALS_RISK_TYPES;
  };

  const OverviewTab = () => (
    <Row gutter={[28, 28]}>
      <Col xs={24} sm={24} md={12} lg={12}>
        <Flex
          gap={isGrowthTypeRiskTolerance(investmentObjective) ? 32 : 19}
          vertical
        >
          <Form.Item name={"investmentObjective"} label={"Goal Type"}>
            <Select
              style={{ width: "100%" }}
              options={getInvestmentObjectiveOptions()}
            />
          </Form.Item>
          <Form.Item
            name={"investmentDuration"}
            label={"Length of Investment (years)"}
          >
            <InputNumber
              min={0}
              max={80}
              step={1}
              style={{ width: "100%" }}
              onClick={selectValue}
            />
          </Form.Item>
          <Form.Item name={"targetAmount"} label={"Target Amount"}>
            <InputNumber
              min={0}
              max={100000000}
              step={1000}
              formatter={getCurrencyValue}
              parser={cleanCurrencyValue}
              style={{ width: "100%" }}
              onClick={selectValue}
            />
          </Form.Item>
          {!isGrowthTypeRiskTolerance(investmentObjective) && (
            <Form.Item
              name={"yearToStartWithdrawals"}
              label={"Begin Withdrawal of funds after"}
            >
              <InputNumber
                min={1}
                max={80}
                formatter={value => (value ? `${value} years` : "")}
                parser={value => value?.replace(" years", "")}
                style={{ width: "100%" }}
                onClick={selectValue}
              />
            </Form.Item>
          )}
          <Form.Item name={"advisorFee"} label={"Advisor Fee"}>
            <InputNumber
              min={0}
              max={2}
              step={0.1}
              formatter={getPercentValue}
              parser={cleanPercentValue}
              onClick={selectValue}
            />
          </Form.Item>
        </Flex>
      </Col>
      <Col xs={24} sm={24} md={12} lg={12}>
        <Flex
          gap={isGrowthTypeRiskTolerance(investmentObjective) ? 32 : 19}
          vertical
        >
          <Form.Item
            name={"investmentAmount"}
            label={"Initial Investment Amount"}
          >
            <InputNumber
              min={0}
              max={100000000}
              step={1000}
              formatter={getCurrencyValue}
              parser={cleanCurrencyValue}
              style={{ width: "100%" }}
              onClick={selectValue}
            />
          </Form.Item>
          <Form.Item name={"contributions"} label={"Monthly Contributions"}>
            <InputNumber
              min={0}
              max={100000}
              step={100}
              formatter={getCurrencyValue}
              parser={cleanCurrencyValue}
              style={{ width: "100%" }}
              onClick={selectValue}
            />
          </Form.Item>
          <Form.Item name={"riskTolerance"} label={"Risk Tolerance"}>
            {isMobile &&
            !isOrganizationOneascent(organization) &&
            !isOrganizationRivershares(organization) ? (
              <Flex gap={8} vertical>
                <StyledButton
                  className={riskTolerance === "Conservative" && "active"}
                  icon={
                    riskTolerance === "Conservative" ? (
                      <ActiveIcon />
                    ) : (
                      <ConservativeIcon />
                    )
                  }
                  onClick={() =>
                    handleMobileRiskToleranceButtonClick("Conservative")
                  }
                  shape={"round"}
                >
                  Conservative
                </StyledButton>
                <StyledButton
                  className={riskTolerance === "Moderate" && "active"}
                  icon={
                    riskTolerance === "Moderate" ? (
                      <ActiveIcon />
                    ) : (
                      <ModerateIcon />
                    )
                  }
                  onClick={() =>
                    handleMobileRiskToleranceButtonClick("Moderate")
                  }
                  shape={"round"}
                >
                  Moderate
                </StyledButton>
                <StyledButton
                  className={riskTolerance === "Aggressive" && "active"}
                  icon={
                    riskTolerance === "Aggressive" ? (
                      <ActiveIcon />
                    ) : (
                      <AggressiveIcon />
                    )
                  }
                  onClick={() =>
                    handleMobileRiskToleranceButtonClick("Aggressive")
                  }
                  shape={"round"}
                >
                  Aggressive
                </StyledButton>
              </Flex>
            ) : (
              <Select style={{ width: "100%" }} options={getOptions()} />
            )}
          </Form.Item>
          {!isGrowthTypeRiskTolerance(investmentObjective) && (
            <Form.Item
              name={"withdrawalLevel"}
              label={"Investment Withdrawal in Retirement"}
            >
              <InputNumber
                min={0}
                max={100}
                formatter={value => (value ? `${value}%` : "")}
                parser={value => value.replace("%", "")}
                style={{ width: "100%" }}
                onClick={selectValue}
              />
            </Form.Item>
          )}
        </Flex>
      </Col>
    </Row>
  );

  const OtherIncomeTab = () => (
    <Flex gap={28} style={{ paddingTop: 10 }} vertical>
      <Flex gap={30}>
        <Form.Item name={"socialSecurity_isOn"} valuePropName="checked">
          <Switch />
        </Form.Item>
        <Flex vertical>
          <Form.Item
            name={"socialSecurity"}
            label={"Social Security"}
            style={{ margin: 0 }}
          >
            <InputNumber
              disabled={!socialSecurity_isOn}
              formatter={getCurrencyValue}
              parser={cleanCurrencyValue}
              onClick={selectValue}
              style={{ width: 165 }}
            />
          </Form.Item>
          <Typography.Text style={{ fontSize: 12 }}>
            Need help?{" "}
            <Typography.Link
              style={{
                fontSize: 12,
                color: token.colorUiText,
              }}
              href="https://www.ssa.gov/"
              target="_blank"
            >
              <u style={{ marginRight: 12 }}>Visit ssa.gov</u>
              <OpenInNew />
            </Typography.Link>
          </Typography.Text>
        </Flex>
      </Flex>
      <Flex align={"end"} gap={30}>
        <Form.Item name={"annuities_isOn"} valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item name={"annuities"} label={"Annuities"} style={{ margin: 0 }}>
          <InputNumber
            disabled={!annuities_isOn}
            formatter={getCurrencyValue}
            parser={cleanCurrencyValue}
            onClick={selectValue}
            style={{ width: 165 }}
          />
        </Form.Item>
      </Flex>
      <Flex align={"end"} gap={30}>
        <Form.Item name={"otherIncome_isOn"} valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item
          name={"otherIncome"}
          label={"Other Income"}
          style={{ margin: 0 }}
        >
          <InputNumber
            disabled={!otherIncome_isOn}
            formatter={getCurrencyValue}
            parser={cleanCurrencyValue}
            onClick={selectValue}
            style={{ width: 165 }}
          />
        </Form.Item>
      </Flex>
    </Flex>
  );

  return (
    <StyledModal
      closeIcon={<Close />}
      footer={
        <Flex gap={16} vertical>
          <Button
            block
            disabled={loading}
            shape={"round"}
            size={"large"}
            style={{
              background: "#1D5568",
              color: "#ffffff",
            }}
            form={"goalSettingsForm"}
            htmlType={"submit"}
          >
            Save & Update Goal
          </Button>
          <Button
            block
            type={"primary"}
            onClick={handleClose}
            shape={"round"}
            size={"large"}
          >
            Cancel & Close
          </Button>
        </Flex>
      }
      onCancel={handleClose}
      open={open}
      title={"Goal Settings"}
      width={514}
    >
      <Form
        form={form}
        id={"goalSettingsForm"}
        layout={"vertical"}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
      >
        <Tabs
          defaultActiveKey={"overview"}
          items={[
            {
              children: <OverviewTab />,
              key: "overview",
              label: "Overview",
            },
            {
              children: <OtherIncomeTab />,
              disabled: isGrowthTypeRiskTolerance(investmentObjective),
              key: "otherIncome",
              label: "Other Income",
            },
          ]}
          tabPosition={"top"}
        />
      </Form>
    </StyledModal>
  );
};

export default ModalGoalSettings;
