import { useEffect, useState } from "react";
import { Button, Col, Flex, Row, Space, theme } from "antd";
import RiskToleranceSection from "./components/RiskToleranceSection";
import WhatIsYourGoalSection from "./components/WhatIsYourGoalSection";
import YourInvestmentSection from "./components/YourInvestmentSection";

import StylesContainer from "./styles";

import { defaultProposalData } from "./constants";

import { getPercentValue } from "../../../../utils/helpers/general";

import Logo from "../../images/rivershares_logo_light.svg";

const QuestionnaireContentRivershares = ({
  open,
  handleClose,
  handleCreateProposal,
}) => {
  const { token } = theme.useToken();
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [proposalData, setProposalData] = useState(defaultProposalData);

  useEffect(() => {
    if (!open) {
      setCurrentStepIndex(0);
      setProposalData(defaultProposalData);
    }
  }, [open]);

  const updateProposalData = (key, value) =>
    setProposalData(state => ({
      ...state,
      [key]: value,
    }));

  const handleGuideStepIndexChange = newStepIndex => {
    if (newStepIndex === -1) {
      handleClose();
    } else if (newStepIndex === 3) {
      handleCreateProposal({
        ...proposalData,
        contributions: proposalData.contributions * 12,
        targetAmount: 0,
        advisorFee: "1%",
        withdrawalLevel: getPercentValue(proposalData.withdrawalLevel ?? 4),
        yearToStartWithdrawals: 1,
      });
    } else {
      setCurrentStepIndex(newStepIndex);
    }
  };

  return (
    <StylesContainer fontFamily={token.proposalGuideFontPrimary} wrap={false}>
      <Col className={"left-side-container"} flex={"330px"}>
        <Row
          align={"middle"}
          style={{ gap: 20, flexDirection: "column", padding: "50px 20px" }}
        >
          <img alt="Logo" src={Logo} style={{ width: 213 }} />
          <Space size={10}>
            {[...Array(3).keys()].map(it => (
              <div
                className={"step-marker"}
                key={it}
                style={{
                  background: it <= currentStepIndex ? "#fff" : "transparent",
                }}
              />
            ))}
          </Space>
        </Row>
      </Col>
      <Col flex={"auto"} className={"steps-container"}>
        <Flex
          align={"center"}
          justify={"center"}
          vertical
          style={{ height: "100%" }}
        >
          <Space direction="vertical" size={0} style={{ maxWidth: 850 }}>
            {currentStepIndex === 0 && (
              <WhatIsYourGoalSection
                proposalData={proposalData}
                updateProposalData={updateProposalData}
              />
            )}
            {currentStepIndex === 1 && (
              <RiskToleranceSection updateProposalData={updateProposalData} />
            )}
            {currentStepIndex === 2 && (
              <YourInvestmentSection
                proposalData={proposalData}
                updateProposalData={updateProposalData}
              />
            )}
            <Flex gap={40} style={{ marginTop: 47 }}>
              <Button
                onClick={() => handleGuideStepIndexChange(currentStepIndex - 1)}
                size={"large"}
                shape={"round"}
                style={{
                  color: "#848484",
                  fontSize: 22,
                  padding: 0,
                  width: 68,
                  zIndex: 4,
                }}
                type={"text"}
              >
                {currentStepIndex === 0 ? "Close" : "Back"}
              </Button>
              <Button
                shape={"round"}
                size={"large"}
                style={{
                  backgroundColor: "#83C390",
                  borderRadius: 5,
                  color: "#FFFFFF",
                  fontSize: 22,
                  width: 253,
                  zIndex: 4,
                }}
                onClick={() => handleGuideStepIndexChange(currentStepIndex + 1)}
              >
                {currentStepIndex === 2 ? "Finish" : "Continue"}
              </Button>
            </Flex>
          </Space>
        </Flex>
      </Col>
    </StylesContainer>
  );
};

export default QuestionnaireContentRivershares;
