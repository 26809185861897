import styled from "styled-components";

const StylesContainer = styled.div`
  .header {
    border-bottom: 1px solid #c9c8c8;
    padding: 23px 63px 17px 35px;

    .title {
      font-size: 20px;
      font-weight: 800;
      color: #1b5568;
      margin: 0;
    }
  }

  .body {
    padding: 42px 78px 27px 35px;
  }

  .ant-table-wrapper .ant-table-thead > tr > td,
  .ant-table-wrapper .ant-table-thead > tr > th {
    font-weight: 500;
    background: transparent;
    border-bottom-color: #8093a3;

    &::before {
      display: none;
    }
  }
`;

export default StylesContainer;
