import { Flex } from "antd";
import Chart from "../Chart";

import { LIST_TENDENCY } from "../../../../../../constants";

const TabCommonGround = ({ coupleCalculations }) => {
  const tendencyType = coupleCalculations.find(
    it => it.total > 110 && it.difference < 50
  )?.type;

  const tendencyData = LIST_TENDENCY.find(it => it.type === tendencyType);

  return (
    <Flex gap={10}>
      <Flex className={"copy-container"} vertical>
        <h4 className={"title"}>{tendencyType}</h4>
        <p
          dangerouslySetInnerHTML={{
            __html: tendencyData?.descriptionUniqueTendencies,
          }}
        />
      </Flex>

      <Chart
        labelAccentBackground={tendencyData?.labelBackgroundColor}
        labelAccentValue={tendencyData?.value}
      />
    </Flex>
  );
};

export default TabCommonGround;
