import { useContext, useMemo, useState } from "react";
import State from "../../../../../../context/user/State";
import { Button, Flex } from "antd";
import TabForUsesMoney from "./components/TabFourUsesMoney";
import TabPersonalityDetails from "./components/TabPersonalityDetails";

import StylesContainer from "./styles";

import { LIST_PERSPECTIVES_POINTS } from "../ModalPerspectives/constant";

import { ReactComponent as ArrowDown } from "./images/arrow_down.svg";

const SectionPerspectiveIndividualResults = () => {
  const [userState] = useContext(State);
  const [activePersonality, setActivePersonality] = useState(
    userState.getPreferenceValue(userState.viewPerspectiveIndividualResults)
      ?.selectedPersonality ?? "Overview"
  );
  const [showOtherMatches, setShowOtherMatches] = useState(false);

  const handleTabChange = activeTab => {
    setActivePersonality(activeTab);
  };

  const handlePersonalitySelect = () => {
    userState.setKeyValue("loading", true);

    userState
      .setPreferenceValue(userState.viewPerspectiveIndividualResults, {
        ...userState.getPreferenceValue(
          userState.viewPerspectiveIndividualResults
        ),
        calculation,
        selectedPersonality: activePersonality,
      })
      .then(() =>
        userState.showSuccess(
          `Planning Personality '${activePersonality}' saved`
        )
      )
      .catch(error => console.log(error))
      .finally(() => userState.setKeyValue("loading", false));
  };

  const getCalculation = () => {
    const perspectiveState = userState.getPreferenceValue(
      userState.viewPerspectiveIndividualResults
    );

    if (!perspectiveState) {
      return {};
    }

    const allAnswers = [
      ...perspectiveState.describeMe.values.map((it, index) => ({
        ...it,
        score: LIST_PERSPECTIVES_POINTS[index],
      })),
      ...perspectiveState.lessMoney.values.map((it, index) => ({
        ...it,
        score: LIST_PERSPECTIVES_POINTS[index],
      })),
      ...perspectiveState.moneyPrimarily.values.map((it, index) => ({
        ...it,
        score: LIST_PERSPECTIVES_POINTS[index],
      })),
      ...perspectiveState.moreMoney.values.map((it, index) => ({
        ...it,
        score: LIST_PERSPECTIVES_POINTS[index],
      })),
      ...perspectiveState.unexpectedlyReceived.values.map((it, index) => ({
        ...it,
        score: LIST_PERSPECTIVES_POINTS[index],
      })),
    ];

    const getTotalByType = type =>
      allAnswers.reduce(
        (acc, it) => (it.type === type ? acc + it.score : acc),
        0
      );

    const givingPoints = 9 || getTotalByType("Giving");
    const investingPoints = 11 || getTotalByType("Investing");
    const savingPoints = 9 || getTotalByType("Saving");
    const spendingPoints = 16 || getTotalByType("Spending");

    const totalPoints = 45;

    const givingWeight = givingPoints / totalPoints;
    const investingWeight = investingPoints / totalPoints;
    const savingWeight = savingPoints / totalPoints;
    const spendingWeight = spendingPoints / totalPoints;

    const getPersonalityWeight = accumulatedWeight =>
      Math.round((accumulatedWeight / (40 / totalPoints)) * 100);

    const enterprisingPersonalityWeight = getPersonalityWeight(
      spendingWeight + investingWeight
    );
    const presentPersonalityWeight = getPersonalityWeight(
      spendingWeight + savingWeight
    );
    const abundantPersonalityWeight = getPersonalityWeight(
      spendingWeight + givingWeight
    );

    const contentPersonalityWeight = getPersonalityWeight(
      savingWeight + givingWeight
    );
    const preparedPersonalityWeight = getPersonalityWeight(
      savingWeight + investingWeight
    );
    const futuristicPersonalityWeight = getPersonalityWeight(
      givingWeight + investingWeight
    );

    return {
      givingPoints: givingPoints,
      investingPoints: investingPoints,
      savingPoints: savingPoints,
      spendingPoints: spendingPoints,
      personalityList: [
        {
          type: "Present",
          value: presentPersonalityWeight,
        },
        {
          type: "Abundant",
          value: abundantPersonalityWeight,
        },
        {
          type: "Enterprising",
          value: enterprisingPersonalityWeight,
        },
        {
          type: "Content",
          value: contentPersonalityWeight,
        },
        {
          type: "Prepared",
          value: preparedPersonalityWeight,
        },
        {
          type: "Futuristic",
          value: futuristicPersonalityWeight,
        },
        {
          type: "Adaptable",
          value: 70,
        },
      ].sort((a, b) => b.value - a.value),
    };
  };

  const calculation = useMemo(() => {
    if (userState.viewPerspectiveIndividualResults) {
      return getCalculation();
    }
  }, [userState.viewPerspectiveIndividualResults]);

  return (
    <StylesContainer gap={16} vertical>
      <Flex align={"end"} className={"header"} justify={"space-between"}>
        <Flex vertical>
          <span className={"label"}>Planning Personality</span>
          <h3 className={"title"} style={{ textTransform: "capitalize" }}>
            {userState.personalInfo?.firstName}{" "}
            {userState.personalInfo?.lastName}
          </h3>
        </Flex>
        <Button
          disabled={activePersonality === "Overview" || userState.loading}
          onClick={handlePersonalitySelect}
          shape={"round"}
          type={"primary"}
        >
          Make this your selection
        </Button>
      </Flex>
      <Flex gap={16}>
        <Flex className={"tab-container"} gap={8} vertical>
          <div
            className={`tab ${activePersonality === "Overview" ? "active" : ""}`}
            onClick={() => handleTabChange("Overview")}
            style={{ fontSize: 19 }}
          >
            Overview
          </div>
          {calculation?.personalityList?.slice(0, 3)?.map((it, index) => (
            <div
              className={`tab ${it.type === activePersonality ? "active" : ""}`}
              onClick={() => handleTabChange(it.type)}
              key={it.type}
            >
              <h6 className={"title"}>{it.type}</h6>
              <p className={"description"}>
                {" "}
                {it.value}% Match {index === 0 && " - Best"}
              </p>
            </div>
          ))}
          <div
            className={`tab`}
            onClick={() => setShowOtherMatches(lastState => !lastState)}
          >
            <Flex align={"center"} justify={"space-between"}>
              <div>Other Matches</div>
              <ArrowDown
                style={{
                  transform: showOtherMatches ? "rotate(180deg)" : "unset",
                }}
              />
            </Flex>
          </div>
          {showOtherMatches &&
            calculation?.personalityList?.slice(3, 7)?.map(it => (
              <div
                className={`tab ${it.type === activePersonality ? "active" : ""}`}
                onClick={() => handleTabChange(it.type)}
                key={it.type}
              >
                <h6 className={"title"}>{it.type}</h6>
                <p className={"description"}>{it.value}% Match</p>
              </div>
            ))}
        </Flex>

        {activePersonality === "Overview" ? (
          <TabForUsesMoney />
        ) : (
          <TabPersonalityDetails
            activePersonality={activePersonality}
            calculation={calculation}
          />
        )}
      </Flex>
    </StylesContainer>
  );
};

export default SectionPerspectiveIndividualResults;
