import styled from "styled-components";
import { Table } from "antd";

const StyledTable = styled(Table)`
  &.ant-table-wrapper tr > td:nth-child(5),
  &.ant-table-wrapper tr > th:nth-child(5),
  &.ant-table-wrapper
    .ant-table-tbody
    > tr.ant-table-row:hover
    > td:nth-child(5) {
    background: #6dc1c730;
  }
`;

export default StyledTable;
