import { useEffect } from "react";
import dayjs from "dayjs";
import { Button, Flex, Form, Input, InputNumber, Radio, theme } from "antd";

import StyledModal from "./styles";

import {
  cleanCurrencyValue,
  cleanPhoneMask,
  getCurrencyValue,
  selectValue,
  setPhoneMask,
} from "../../../../utils/helpers/general";
import { isManagerAccessProportalOnly } from "../../../../utils/helpers/specialized";

import Close from "../../../../icons/Close";
import { ReactComponent as IconOpenClientView } from "../../images/icon_open_client_view.svg";

const defaultFormValues = {
  assets: 0,
  birthYear: "",
  email: "",
  firstName: "",
  lastName: "",
  maritalStatus: "single",
  phoneAlt: "",
  phoneNumber: "",
  planningType: "goals",
};

const ModalLeadDetails = ({
  activeLeadData,
  handleOpenClientView,
  handleUpdateLeadDetails,
  loading,
  managerAccess,
  onCancel,
  open,
}) => {
  const [form] = Form.useForm();
  const { token } = theme.useToken();

  useEffect(() => {
    if (open) {
      form.setFieldsValue(
        activeLeadData
          ? {
              assets: activeLeadData.valueMap?.leadInitial?.assets,
              birthYear: activeLeadData.birthYear,
              email: activeLeadData.email,
              firstName: activeLeadData.firstName,
              lastName: activeLeadData.lastName,
              maritalStatus: activeLeadData?.valueMap?.guideInitialData
                ?.isMarried
                ? "married"
                : "single",
              phoneAlt: activeLeadData.valueMap?.leadInitial?.phoneAlt,
              phoneNumber: activeLeadData.valueMap?.leadInitial?.phoneNumber,
              planningType: activeLeadData.planningType?.toLowerCase(),
            }
          : defaultFormValues
      );

      if (isManagerAccessProportalOnly(managerAccess)) {
        form.setFieldValue("planningType", "cashflow");
      }
    }
  }, [open]);

  return (
    <StyledModal
      closeIcon={<Close />}
      footer={
        <Flex justify={"space-between"}>
          <Button onClick={onCancel} shape={"round"} type={"text"}>
            Cancel
          </Button>
          <Button
            disabled={loading}
            form={"leadDetailsForm"}
            htmlType={"submit"}
            shape={"round"}
            style={{ width: 147 }}
            type={"primary"}
          >
            Save Updates
          </Button>
        </Flex>
      }
      onCancel={onCancel}
      open={open}
      title={
        <Flex
          align={"center"}
          justify={"space-between"}
          style={{ paddingRight: 40 }}
        >
          <b style={{ fontSize: 18, color: "#1B5568" }}>Lead details</b>
          {activeLeadData?.valueMap?.productMap && (
            <Button
              onClick={() =>
                handleOpenClientView({
                  planningType:
                    activeLeadData.planningType ??
                    activeLeadData.valueMap?.leadInitial?.planningType,
                  userEmail: activeLeadData.email,
                })
              }
              shape={"round"}
              size={"small"}
              style={{ width: 170, background: "#E6F4EF", color: "#1B5568" }}
              type={"primary"}
            >
              <Flex align={"center"} gap={4}>
                <span>Open Advisor View</span>
                <IconOpenClientView />
              </Flex>
            </Button>
          )}
        </Flex>
      }
      token={token}
      width={568}
    >
      <Form
        layout={"vertical"}
        id={"leadDetailsForm"}
        form={form}
        onFinish={handleUpdateLeadDetails}
      >
        <Flex gap={32}>
          <Form.Item
            label={"First Name"}
            name={"firstName"}
            rules={[
              {
                required: true,
              },
            ]}
            style={{ width: "100%" }}
          >
            <Input onClick={selectValue} size={"large"} />
          </Form.Item>
          <Form.Item
            label={"Last Name"}
            name={"lastName"}
            rules={[
              {
                required: true,
              },
            ]}
            style={{ width: "100%" }}
          >
            <Input onClick={selectValue} size={"large"} />
          </Form.Item>
        </Flex>
        <Form.Item
          label={"Email"}
          name={"email"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input
            disabled={activeLeadData}
            onClick={selectValue}
            size={"large"}
          />
        </Form.Item>
        <Flex gap={32}>
          <Form.Item
            label={"Phone"}
            name={"phoneNumber"}
            style={{ width: "100%" }}
          >
            <InputNumber
              formatter={setPhoneMask}
              onClick={selectValue}
              parser={cleanPhoneMask}
              size={"large"}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            label={"Phone Alt"}
            name={"phoneAlt"}
            style={{ width: "100%" }}
          >
            <InputNumber
              formatter={setPhoneMask}
              onClick={selectValue}
              parser={cleanPhoneMask}
              size={"large"}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Flex>
        <Flex align={"end"} gap={32}>
          <Form.Item
            hidden={!managerAccess?.affiliate && !managerAccess?.proportal}
            label={"Planning Type"}
            name={"planningType"}
            style={{ width: "100%" }}
          >
            <Radio.Group>
              {managerAccess?.affiliate && !managerAccess?.proportal ? (
                <Radio value={"goals"}>Goals</Radio>
              ) : managerAccess?.proportal && !managerAccess?.affiliate ? (
                <Radio value={"cashflow"}>Cashflow</Radio>
              ) : (
                <Flex vertical>
                  <Radio value={"goals"}>Goals</Radio>
                  <Radio value={"cashflow"}>Cashflow</Radio>
                </Flex>
              )}
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label={"Initial Investable Assets"}
            name={"assets"}
            style={{ width: "100%" }}
          >
            <InputNumber
              formatter={getCurrencyValue}
              max={10000000}
              min={0}
              onClick={selectValue}
              parser={cleanCurrencyValue}
              size={"large"}
              step={1000}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Flex>
        <Flex gap={32}>
          <Form.Item
            label={"Marital Status"}
            name={"maritalStatus"}
            style={{ width: "100%" }}
          >
            <Radio.Group>
              <Flex vetical>
                <Radio value={"single"}>Single</Radio>
                <Radio value={"married"}>Married</Radio>
              </Flex>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label={"Birth Year"}
            name={"birthYear"}
            rules={[
              {
                type: "number",
                min: 1940,
                max: dayjs().format("YYYY") - 5,
              },
            ]}
            style={{ width: "100%" }}
          >
            <InputNumber
              id={"birthYearInput"}
              inputMode={"numeric"}
              onClick={selectValue}
              pattern={"[0-9]*"}
              placeholder={"YYYY"}
              size={"large"}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Flex>
      </Form>
    </StyledModal>
  );
};

export default ModalLeadDetails;
