import styled from "styled-components";
import { Flex } from "antd";

export const StyleContainer = styled(Flex)`
  font-family: Roboto, sans-serif;

  .header {
    color: #071a4d;
    font-style: normal;
    line-height: 140%;
    text-align: center;

    .title {
      font-size: 22px;
      font-weight: 500;
    }

    .sub-title {
      font-size: 18px;
      font-weight: 700;
    }
  }
`;
