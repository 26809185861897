import styled from "styled-components";
import { Flex } from "antd";

export const AnalyticsValue = styled.div`
  line-height: 34px;
  font-size: 30px;
  font-weight: 700;
  color: #1b5568;
`;

export const AnalyticsLabel = styled.div`
  font-size: 15px;
  color: #5a607f;
`;

export const AnalyticsDescription = styled.div`
  font-size: 12px;
  color: #7e84a3;
`;

export const Marker = styled.span`
  display: inline-block;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: ${props => props.color};
`;

export const StatisticCard = styled(Flex)`
  width: 223px;
  background: #ffffff;
  min-height: 126px;
  padding: 19px 22px 25px;
  border-radius: 8px;
  box-shadow: 0 0 16px #0000000d;
`;
