import styled from "styled-components";
import { InputNumber, Typography } from "antd";

export const Label = styled(Typography.Text)`
  color: #192849;
`;

export const StyledInputNumber = styled(InputNumber)`
  width: 159px;
  border-radius: 24px;

  &.ant-input-number .ant-input-number-handler-wrap {
    right: 10px;
  }
`;
