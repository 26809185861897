import { useContext } from "react";
import State from "../../../../context/user/State";
import ContainerHelpButtons from "../../../../organisms/SectionAskAdvice/components/ContainerHelpButtons";

import StyledHeader from "./styles";

const Header = ({ children, hideNavbar, style }) => {
  const [userState] = useContext(State);

  const handleAskButtonClick = activeTabName => {
    userState.setKeyValue("openModalAskAdvice", true);
    userState.setKeyValue("sectionAskAdviceActiveTab", activeTabName);
  };

  return (
    <StyledHeader style={style}>
      <div>{children}</div>
      {!hideNavbar && (
        <ContainerHelpButtons
          handleButtonClick={handleAskButtonClick}
          isAdvisorButtonActive={
            userState.openModalAskAdvice &&
            userState.sectionAskAdviceActiveTab === "advisor"
          }
          isAiButtonActive={
            userState.openModalAskAdvice &&
            userState.sectionAskAdviceActiveTab !== "advisor"
          }
        />
      )}
    </StyledHeader>
  );
};

export default Header;
