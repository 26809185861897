import { useContext } from "react";
import State from "../../../../context/manager/State";
import { Button, Collapse, Flex } from "antd";

import StylesContainer from "./styles";

import IconPenny from "../../images/IconPenny";

const text = `
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse tempus ac nisi nec euismod. Praesent venenatis semper facilisis. Praesent in eleifend ligula, sollicitudin elementum est. Donec tristique gravida felis, sit amet luctus leo euismod ac. Phasellus semper lacus a pretium aliquet. Nullam maximus mattis eros, ut varius mauris. Vestibulum vitae felis sapien. Ut tincidunt efficitur sem, at blandit dui rhoncus a. Vivamus cursus dignissim dapibus. Interdum et malesuada fames ac ante ipsum primis in faucibus. Etiam sit amet urna hendrerit, egestas urna vitae, venenatis sapien. Mauris ac tempor felis. Nulla scelerisque risus et dignissim rutrum.
Aliquam non enim magna. Nullam egestas, neque a faucibus maximus, augue arcu iaculis nulla, sed posuere ex dolor eget quam. Cras molestie ex id quam aliquam, non sagittis erat pretium. Integer viverra, lectus sit amet ornare volutpat, arcu ligula posuere nibh, vel dictum augue justo vel purus. Duis vel mauris vitae lorem luctus posuere. Curabitur consectetur aliquet nisi gravida dapibus. Duis viverra nibh in purus ultrices, in cursus velit .
Phasellus sem est, dapibus at eros vitae, pharetra blandit enim. Duis elit quam, luctus vulputate condimentum at, suscipit egestas quam. Praesent viverra lacus in risus ultricies feugiat.
`;

const data = [
  {
    question: "How much do i need to retire in 25 years?",
    email: "anne.coleson@outlook.com",
    source: "Goals",
    date: "12/04/23 4:36pm",
  },
  {
    question:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse tempus ac nisi nec euismod?",
    email: "carbrechtson@outlook.com",
    source: "Cashflow",
    date: "11/02/23 4:36pm",
  },
  {
    question: "How much do i need to retire in 25 years?",
    email: "anne.coleson@outlook.com",
    source: "Goals",
    date: "05/07/23 4:36pm",
  },
];

const PageAskPenny = () => {
  const [managerState] = useContext(State);

  return (
    <StylesContainer>
      <Flex align={"center"} className={"header"} justify={"space-between"}>
        <h2 className={"title"}>Conversations</h2>
        <Button
          disabled={!managerState.access?.penny}
          icon={<IconPenny />}
          onClick={() => managerState.openModal("openModalAskAdvice")}
          shape={"round"}
          type={"primary"}
        >
          <span>
            Ask<b>Penny</b>
          </span>
        </Button>
      </Flex>
      <div className="body">
        <div className="content-container">
          <Flex gap={32} style={{ marginBottom: 11, paddingLeft: 5 }}>
            <b style={{ width: 390 }}>Question</b>
            <b style={{ width: 185 }}>User</b>
            <b style={{ width: 100 }}>Source</b>
            <b>Time</b>
          </Flex>
          <Collapse
            accordion
            items={data?.map((it, index) => ({
              key: index,
              label: (
                <Flex gap={32}>
                  <span style={{ width: 354 }}>{it.question}</span>
                  <span style={{ width: 185 }}>{it.email}</span>
                  <span style={{ width: 100 }}>{it.source} app</span>
                  <span>{it.date}</span>
                </Flex>
              ),
              children: <p>{text}</p>,
            }))}
          />
        </div>
      </div>
    </StylesContainer>
  );
};

export default PageAskPenny;
