import { useEffect, useRef } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import dayjs from "dayjs";
import { Col, Row, theme } from "antd";
import ChartIncomeWithTypes from "../../../../molecules/ChartIncomeWithTypes";
import ChartSkeleton from "../../../../atoms/ChartSkeleton";

import { DownloadLink, DownloadsContainer, SectionTitle } from "./styles";

import { getGrowthChartConfig } from "../../../../utils/charts";
import pieChartConfig from "./configs/pieChartConfig";

import { DownloadOutlined } from "@ant-design/icons";

const OverviewTab = ({
  closeModal,
  investmentAssumptions,
  liveAssessment,
  loading,
  product,
  productsList,
  viewMode,
}) => {
  const { token } = theme.useToken();
  const chartRef = useRef(null);

  useEffect(() => {
    if (viewMode === "pdf") {
      const storedData = localStorage.getItem("pieChartWithLegendSvg");

      const interval = setInterval(() => {
        if (!storedData) {
          const pieChartWithLegendSvg = chartRef?.current?.chart?.getSVG();

          if (pieChartWithLegendSvg) {
            localStorage.setItem(
              "pieChartWithLegendSvg",
              pieChartWithLegendSvg
            );
            closeModal();
          }
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [viewMode]);

  const getMainChartSeries = (series = []) => {
    liveAssessment?.grandTotalColumn &&
      series.push({
        name: "Calculated progress",
        lineWidth: 2,
        color: "#537CF1",
        zoneAxis: "x",
        marker: {
          enabled: false,
        },
        data: liveAssessment.grandTotalColumn,
      });

    return series;
  };

  const getPieChartSeries = (series = []) => {
    if (product?.assets) {
      series.push({
        colorByPoint: true,
        data: product.assets.map(it => ({
          name: it.assetName,
          y: it.investmentPercent,
        })),
        name: "Selected",
        size: "100%",
      });
    }

    return series;
  };

  return product &&
    (product?.goalId === "emergencySavings" ||
      product?.name === "Savings Account") ? (
    <div>
      <div style={{ fontSize: 18 }}>About {product.name}</div>
      <p>{product.objective}</p>
    </div>
  ) : (
    <>
      {viewMode !== "pdf" && (
        <>
          <ChartSkeleton loading={loading} height={250}>
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                ...getGrowthChartConfig({
                  birthYear: product.currentAge
                    ? dayjs().format("YYYY") - product.currentAge
                    : product.birthYear,
                }),
                series: getMainChartSeries(),
              }}
            />
          </ChartSkeleton>
          <div style={{ marginTop: 32 }}>
            <ChartIncomeWithTypes
              loading={loading}
              productsList={productsList}
              productsLiveAssessment={liveAssessment ? [liveAssessment] : []}
              productData={{
                ...investmentAssumptions,
                ...product,
              }}
            />
          </div>
        </>
      )}
      {product?.productName !== "initial" && (
        <Row
          gutter={[24, 24]}
          style={{
            padding: "24px 0 32px",
          }}
        >
          <Col
            xs={{
              order: 2,
              span: 24,
            }}
            sm={{
              order: 2,
              span: 24,
            }}
            md={{
              order: 2,
              span: 24,
            }}
            lg={{
              order: 1,
              span: 8,
            }}
          >
            <SectionTitle>Investment Philosophy</SectionTitle>
            <div>{product?.investmentPhilosophy}</div>
          </Col>
          <Col
            xs={{
              order: 3,
              span: 24,
            }}
            sm={{
              order: 3,
              span: 24,
            }}
            md={{
              order: 3,
              span: 24,
            }}
            lg={{
              order: 2,
              span: 8,
            }}
          >
            <SectionTitle>Objective</SectionTitle>
            <div dangerouslySetInnerHTML={{ __html: product.objective }} />
            {product.downloads && (
              <DownloadsContainer>
                {product.downloads.map(
                  download =>
                    download.url && (
                      <DownloadLink
                        key={`${product._id}-${download.name}`}
                        href={download.url}
                        target="_blank"
                      >
                        <DownloadOutlined />
                        <div
                          style={{
                            marginLeft: "8px",
                            color: token.colorLink,
                          }}
                        >
                          {download.name}
                        </div>
                      </DownloadLink>
                    )
                )}
              </DownloadsContainer>
            )}
          </Col>
          <Col
            xs={{
              order: 1,
              span: 24,
            }}
            sm={{
              order: 1,
              span: 24,
            }}
            md={{
              order: 1,
              span: 24,
            }}
            lg={{
              order: 3,
              span: 8,
            }}
          >
            <SectionTitle>Portfolio Mix</SectionTitle>
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                ...pieChartConfig,
                chart: {
                  ...pieChartConfig.chart,
                  width: viewMode === "pdf" ? 300 : 245,
                },
                legend: {
                  ...pieChartConfig.legend,
                  itemStyle: {
                    fontSize: viewMode === "pdf" ? 20 : 14,
                  },
                },
                colors: token.chartColors,
                series: getPieChartSeries(),
              }}
              ref={chartRef}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default OverviewTab;
