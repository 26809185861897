import Proposal from "../../proposal/templates/Proposal";

import IconGoals from "../../../icons/IconGoals";

export default [
  {
    path: "/rivershares-proposal",
    Component: Proposal,
    Icon: IconGoals,
    text: "Goals",
  },
];
