const IconProduct = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.96875 3.09375H3.4375C3.24844 3.09375 3.09375 3.24844 3.09375 3.4375V9.96875C3.09375 10.1578 3.24844 10.3125 3.4375 10.3125H9.96875C10.1578 10.3125 10.3125 10.1578 10.3125 9.96875V3.4375C10.3125 3.24844 10.1578 3.09375 9.96875 3.09375ZM8.85156 8.85156H4.55469V4.55469H8.85156V8.85156ZM18.5625 3.09375H12.0312C11.8422 3.09375 11.6875 3.24844 11.6875 3.4375V9.96875C11.6875 10.1578 11.8422 10.3125 12.0312 10.3125H18.5625C18.7516 10.3125 18.9062 10.1578 18.9062 9.96875V3.4375C18.9062 3.24844 18.7516 3.09375 18.5625 3.09375ZM17.4453 8.85156H13.1484V4.55469H17.4453V8.85156ZM18.5625 11.6875H12.0312C11.8422 11.6875 11.6875 11.8422 11.6875 12.0312V18.5625C11.6875 18.7516 11.8422 18.9062 12.0312 18.9062H18.5625C18.7516 18.9062 18.9062 18.7516 18.9062 18.5625V12.0312C18.9062 11.8422 18.7516 11.6875 18.5625 11.6875ZM17.4453 17.4453H13.1484V13.1484H17.4453V17.4453ZM9.10938 15.2969H6.35938V12.5469C6.35938 12.4523 6.28203 12.375 6.1875 12.375H5.15625C5.06172 12.375 4.98438 12.4523 4.98438 12.5469V15.2969H2.23438C2.13984 15.2969 2.0625 15.3742 2.0625 15.4688V16.5C2.0625 16.5945 2.13984 16.6719 2.23438 16.6719H4.98438V19.4219C4.98438 19.5164 5.06172 19.5938 5.15625 19.5938H6.1875C6.28203 19.5938 6.35938 19.5164 6.35938 19.4219V16.6719H9.10938C9.20391 16.6719 9.28125 16.5945 9.28125 16.5V15.4688C9.28125 15.3742 9.20391 15.2969 9.10938 15.2969Z"
      fill="currentColor"
    />
  </svg>
);

export default IconProduct;
