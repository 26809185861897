import styled from "styled-components";
import { Flex } from "antd";

const StylesContainer = styled(Flex)`
  margin: 45px auto;
  width: 1084px;

  .header {
    .label {
      color: #000;
      font-family: Roboto, sans-serif;
      font-weight: 300;
      line-height: normal;
    }

    .title {
      color: #000;
      font-family: Roboto, sans-serif;
      font-size: 26px;
      font-weight: 700;
      line-height: normal;
      margin: 0;
    }
  }

  .ant-tabs-card {
    gap: 16px;

    > .ant-tabs-nav .ant-tabs-tab {
      background: #edf5f9;
      border: unset;
      border-radius: 8px;
      padding: 16px;
      text-align: left;
      white-space: pre-wrap;
      width: 200px;

      .ant-tabs-tab-btn {
        color: #3c3c3c;
        font-size: 19px;
        font-weight: 700;
      }
    }

    > .ant-tabs-nav .ant-tabs-tab-active {
      background: #689fb6;

      .ant-tabs-tab-btn {
        color: #fff;
      }
    }

    .ant-tabs-content-holder {
      background: #e5f5f5;
      border: unset;
      border-radius: 12px;
      padding: 28px;
    }
  }

  .copy-container {
    color: #1e1e1e;
    font-size: 16px;
    width: 380px;

    .title {
      font-size: 34px;
      line-height: 39px;
      margin: 0 0 16px;
      text-transform: capitalize;
    }

    .label {
      font-size: 14px;
    }

    .selected-personality {
      font-size: 22px;
      line-height: 120%;
      margin: 0 0 12px;
    }
  }
`;

export default StylesContainer;
