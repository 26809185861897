import { Button, Flex, Image, Layout, theme } from "antd";
import PennyChatToggler from "../../../organisms/SectionAskAdvice/components/PennyChatToggler";

import {
  isOrganizationLevel,
  isOrganizationOneascent,
  isOrganizationRivershares,
} from "../../../utils/helpers/specialized";

import GetAdvice from "../../images/GetAdvice";

import { ReactComponent as LevelLogo } from "../../../ecosystems/level/images/logo.svg";
import { ReactComponent as OneAscentSlogan } from "../../../ecosystems/oneascent/images/slogan.svg";

const Header = ({
  handleButtonBackToDashboard,
  organization,
  openModal,
  showButtonBackToDashboard,
  userManagerData,
}) => {
  const { token } = theme.useToken();

  return (
    <Layout.Header style={{ background: token.goalsHeaderBackground }}>
      {isOrganizationOneascent(organization) && (
        <Flex justify={"space-between"}>
          <Flex gap={46}>
            <Image
              id={"headerLogo"}
              preview={false}
              src={token.logoSrc}
              width={160}
            />
            {showButtonBackToDashboard && (
              <Button
                onClick={handleButtonBackToDashboard}
                shape={"round"}
                size={"large"}
              >
                Back to Dashboard
              </Button>
            )}
          </Flex>
          <OneAscentSlogan id={"oneascentSlogan"} style={{ width: 160 }} />
        </Flex>
      )}
      {isOrganizationRivershares(organization) && (
        <Flex>
          <Image
            id={"headerLogo"}
            preview={false}
            src={token.logoSrc}
            width={160}
          />
        </Flex>
      )}
      {isOrganizationLevel(organization) &&
        userManagerData?.access?.penny(
          <Flex justify={"space-between"}>
            <LevelLogo id={"headerLogo"} style={{ width: 160 }} />
            <PennyChatToggler
              handleClick={() => openModal("openModalAskAdvice")}
            />
          </Flex>
        )}
      {!organization && (
        <Flex justify={"space-between"}>
          <Image
            id={"headerLogo"}
            preview={false}
            src={token.logoSrc}
            width={160}
          />
          <Button
            type="secondary"
            shape="round"
            style={{
              width: 40,
              padding: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => openModal("openModalAskAdvice")}
          >
            <GetAdvice />
          </Button>
        </Flex>
      )}
    </Layout.Header>
  );
};

export default Header;
