import { useContext } from "react";
import dayjs from "dayjs";
import State from "../../../../../../context/user/State";
import { DatePicker, InputNumber, Row, Space, theme } from "antd";

import { Label } from "./styles";

import {
  cleanCurrencyValue,
  getCurrencyValue,
  selectValue,
} from "../../../../../../utils/helpers/general";
import { getGoalIdByGoalName } from "../../../../../../utils/helpers/specialized";

import Close from "../../../../../../icons/Close";

const RowUpdateProgress = ({
  data = {},
  goalName,
  showLabels,
  showRemoveButton = true,
  setProgressValues,
  setNewProgressItemData,
}) => {
  const { token } = theme.useToken();
  const [userState] = useContext(State);

  const handleValueChange = (key, value) => {
    if (data.id === undefined) {
      setNewProgressItemData(state => ({
        ...state,
        [key]: value,
      }));
    } else {
      setProgressValues(state =>
        state.map(it => {
          if (it.id === data.id) {
            return {
              ...it,
              [key]: value,
            };
          } else {
            return it;
          }
        })
      );
    }
  };

  const handleRemove = () => {
    setProgressValues(state => state.filter(it => it.id !== data.id));
  };

  return (
    <Row style={{ marginBottom: 19 }}>
      <Space size={12}>
        <Space direction="vertical" size={1}>
          {showLabels && <Label color={token.colorGrey1}>Actual Balance</Label>}
          <InputNumber
            className="input-custom actual-progress-value"
            value={data.actualTotal ?? 0}
            min={0}
            max={100000000}
            step={1000}
            style={{ width: 125 }}
            formatter={getCurrencyValue}
            parser={cleanCurrencyValue}
            onClick={selectValue}
            onChange={value => handleValueChange("actualTotal", value)}
          />
        </Space>
        <Space direction="vertical" size={1}>
          {showLabels && <Label color={token.colorGrey1}>As of Date</Label>}
          <DatePicker
            allowClear={false}
            className="input-custom actual-progress-date"
            value={data.date && dayjs(data.date.$date)}
            format="MM/DD/YY"
            maxDate={dayjs()}
            minDate={dayjs(
              userState.productScenariosMap[
                getGoalIdByGoalName(
                  userState.getPreferenceValue("productMap"),
                  goalName
                )
              ][0].timestamp
            )}
            placeholder="MM/DD/YY"
            showNow={false}
            style={{ width: 115 }}
            onChange={date => handleValueChange("date", { $date: date.$d })}
          />
        </Space>
        {showRemoveButton && (
          <Space
            direction="vertical"
            size={8}
            onClick={handleRemove}
            className="pointer"
          >
            {showLabels && <Label color={token.colorGrey1}></Label>}
            <Close color={token.colorGrey2} />
          </Space>
        )}
      </Space>
    </Row>
  );
};

export default RowUpdateProgress;
