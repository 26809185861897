import dayjs from "dayjs";

export const capitalizeString = string =>
  string ? string.charAt(0).toUpperCase() + string.slice(1) : "";

export const cleanCurrencyValue = value => value.replace(/[^\d.]/g, "");

export const cleanPercentValue = value => {
  if (value === undefined) {
    return "";
  }
  const valueString = value + "";

  return valueString.replaceAll("%", "");
};

export const cleanFromHtmlTags = string => string?.replace(/(<([^>]+)>)/gi, "");

export const convertDecimalIntoDate = decimalDate => {
  const year = Math.floor(decimalDate);
  const dayOfYear = Math.round((decimalDate - year) * 365);
  const date = new Date(year, 0).setDate(dayOfYear);

  return dayjs(date).format("MM/DD/YYYY");
};

export const getCurrencyValue = value => {
  if (value === "" || value === undefined || value === null) {
    return "";
  } else {
    return "$" + (value + "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};

export const getCurrencyValueWithRoundSign = (value = 0) => {
  if (typeof value === "string") {
    value = +value.replaceAll(",", ".");
  }

  if (value >= 1000000000 || value <= -1000000000) {
    return getCurrencyValue((value / 1000000000).toFixed(1)) + "B";
  } else if (value >= 1000000 || value <= -1000000) {
    return getCurrencyValue((value / 1000000).toFixed(1)) + "M";
  } else if (value >= 1000 || value <= -1000) {
    return getCurrencyValue(Math.round(value / 1000)) + "K";
  } else {
    return getCurrencyValue(value);
  }
};

export const getImageDataURL = svgXml =>
  `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(svgXml)))}`;

export const getPercentValue = value => {
  if (value === undefined) {
    return "";
  }

  const valueString = value + "";

  if (valueString.includes("%")) {
    return valueString.replaceAll("%%", "%");
  } else {
    return valueString + "%";
  }
};

export const isValidEmail = email => {
  if (!email) return false;

  const regExp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regExp.test(email);
};

export const normalizePhoneInput = (value, previousValue) => {
  // return nothing if no value
  if (!value) return value;

  // only allows 0-9 inputs
  const currentValue = value.replace(/[^\d]/g, "");
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    // returns: "x", "xx", "xxx"
    if (cvLength < 4) return currentValue;

    // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
    if (cvLength < 7)
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;

    // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`;
  }
};

export const percentIncrease = ({
  compareValue,
  compareWithValue,
  maxPercent,
  minPercent,
}) => {
  let percent;

  if (compareValue === 0 && compareWithValue === 0) {
    return 0;
  } else if (compareWithValue === 0) {
    return 100;
  } else {
    if (compareWithValue > compareValue) {
      percent = -100 + (compareValue / compareWithValue) * 100;
    } else {
      percent = (compareValue / compareWithValue) * 100 - 100;
    }
  }
  const roundedPercent = Math.floor(percent);

  return maxPercent !== undefined && roundedPercent > maxPercent
    ? maxPercent
    : roundedPercent < minPercent
      ? minPercent
      : roundedPercent;
};

export const setPhoneMask = (phoneNumber = "") => {
  const onlyDigitsValue = phoneNumber.replace(/\D/g, "");

  if (onlyDigitsValue === "") {
    return "";
  } else {
    return (
      "(" +
      onlyDigitsValue.substring(0, 3) +
      ") " +
      onlyDigitsValue.substring(3, 6) +
      "-" +
      onlyDigitsValue.substring(6, 10)
    );
  }
};

export const cleanPhoneMask = phoneString =>
  phoneString
    .replaceAll("(", "")
    .replaceAll(" ", "")
    .replaceAll("-", "")
    .replaceAll(")", "");

export const scrollToTop = () =>
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

export const selectValue = event => event?.target?.select();

export const setHeadAttributes = ({
  faviconUrl,
  metaDescription,
  documentTitle,
}) => {
  if (documentTitle) document.title = documentTitle;

  if (faviconUrl) document.querySelector("link[rel='icon']").href = faviconUrl;

  if (metaDescription)
    document.querySelector("meta[name='description']").content =
      metaDescription;
};

export const stringToCamelCase = string =>
  string?.length ? string.charAt(0).toUpperCase() + string.slice(1) : "";
