import { useContext, useState } from "react";
import dayjs from "dayjs";
import State from "../../../../context/manager/State";
import { Button, Input, Row, Table, theme } from "antd";
import ConvertClientDialog from "../ConvertClientDialog";
import MenuActions from "../../../../atoms/MenuActions";
import ModalArchiveManagedUser from "../../../../molecules/ModalArchiveManagedUser";
import ModalViewIpq from "../../../../ecosystems/oneascent/components/ModalViewIpq";

import StylesContainer from "./styles";

import { archiveLead, openProposal } from "../../../../utils/requests/manager";
import {
  getLastSavedProposalProps,
  isAdminView,
  isManagerTypeIpq,
  isOrganizationOneascent,
  openProposalWithConfig,
} from "../../../../utils/helpers/specialized";
import { getCurrencyValue } from "../../../../utils/helpers/general";

const getColumns = ({
  handleActionArchiveClient,
  handleActionConvertUser,
  handleActionEditUser,
  handleActionNewIpq,
  handleActionViewIpq,
  handleCreateProposalButton,
  instanceLabel,
  managerState,
  searchedText,
  token,
}) =>
  [
    {
      dataIndex: "name",
      filteredValue: [searchedText],
      key: "name",
      onFilter: (value, record) =>
        String(record.name).toLowerCase().includes(value.toLowerCase()) ||
        String(record.email).toLowerCase().includes(value.toLowerCase()),
      render: (text, restValues) =>
        restValues.proposalGenerated ? (
          <a
            onClick={() =>
              openProposal({
                userEmail: restValues.email,
                organization: managerState.organization?.name,
              })
            }
          >
            <b>{text}</b>
          </a>
        ) : (
          <b>{text}</b>
        ),
      sorter: (a, b) => a.name.localeCompare(b.name),
      title: `${instanceLabel[0].toUpperCase() + instanceLabel.slice(1)} Name`,
    },
    {
      dataIndex: "email",
      key: "email",
      render: value => <a href={"mailto:" + value}>{value}</a>,
      sorter: (a, b) => a.email.localeCompare(b.email),
      title: "Email",
    },
    {
      align: "right",
      dataIndex: "assets",
      key: "assets",
      render: value => getCurrencyValue(value),
      sorter: (a, b) => a.assets - b.assets,
      title: "Assets",
    },
    {
      align: "left",
      hidden: !isManagerTypeIpq(managerState.onboardingQuestionnaireType),
      key: "ipq",
      render: (_, allValues) =>
        allValues.prospectObjective && (
          <div
            onClick={() => handleActionViewIpq(allValues.email)}
            style={{
              color: token.colorSecondaryButton,
              cursor: "pointer",
            }}
          >
            View
          </div>
        ),
      title: "IPQ",
    },
    {
      align: "right",
      dataIndex: "proposalGenerated",
      key: "proposalGenerated",
      render: (value, restValues) =>
        value ? (
          dayjs(value).format("MM/DD/YYYY")
        ) : (
          <Button
            shape="round"
            size="small"
            style={{
              borderColor: token.colorSecondaryButton,
              color: token.colorSecondaryButton,
            }}
            onClick={() => handleCreateProposalButton(restValues.email)}
            disabled={isAdminView()}
          >
            Create Proposal
          </Button>
        ),
      sorter: (a, b) => dayjs(a.proposalGenerated) - dayjs(b.proposalGenerated),
      title: "Proposal Generated",
      width: 150,
    },
    {
      className: "actions-menu-table-cell",
      key: "actionsMenu",
      render: (_, restValues) => (
        <MenuActions
          items={getItems({
            email: restValues.email,
            handleActionArchiveClient,
            handleActionConvertUser,
            handleActionEditUser,
            handleActionNewIpq,
            handleActionViewIpq,
            instanceLabel,
            managerType: managerState.onboardingQuestionnaireType,
          })}
        />
      ),
      title: "",
      width: 40,
    },
  ].filter(it => !it.hidden);

const getItems = ({
  email,
  handleActionArchiveClient,
  handleActionConvertUser,
  handleActionEditUser,
  handleActionNewIpq,
  handleActionViewIpq,
  instanceLabel,
  managerType,
}) =>
  [
    {
      key: "edit",
      label: (
        <div onClick={() => handleActionEditUser(email)}>
          Edit{" "}
          <span style={{ textTransform: "capitalize" }}>{instanceLabel}</span>
        </div>
      ),
    },
    {
      key: "move",
      label: (
        <div
          onClick={() => {
            handleActionConvertUser(email);
          }}
        >
          Mark as Client
        </div>
      ),
    },
    {
      hidden: !isManagerTypeIpq(managerType),
      key: "newIpq",
      label: <div onClick={() => handleActionNewIpq(email)}>New IPQ</div>,
    },
    {
      hidden: !isManagerTypeIpq(managerType),
      key: "viewIpq",
      label: <div onClick={() => handleActionViewIpq(email)}>View IPQ</div>,
    },
    {
      type: "divider",
    },
    {
      key: "archive",
      label: (
        <div
          style={{
            color: "red",
            textTransform: "capitalize",
          }}
          onClick={() => handleActionArchiveClient(email)}
        >
          Archive
        </div>
      ),
    },
  ].filter(it => !it.hidden);

const ProspectsTable = ({ instanceLabel }) => {
  const { token } = theme.useToken();
  const [managerState] = useContext(State);
  const [searchedText, setSearchedText] = useState("");

  const handleArchiveClient = email => {
    managerState.closeModal("archiveManagedUserModal");
    managerState.showLoader();

    archiveLead({
      email: managerState.activeEmail,
      userManagerEmail: managerState._id,
    })
      .then(() =>
        managerState.setAdminData().then(() => {
          managerState.hideLoader();
          managerState.setKeyValue("activeEmail", null);
          managerState.showSuccess(
            `Client ${managerState.activeEmail} archived`
          );
        })
      )
      .catch(error => {
        console.log(error);
        managerState.hideLoader();
      });
  };

  const handleActionArchiveClient = email => {
    managerState.setKeyValue("activeEmail", email);
    managerState.openModal("archiveManagedUserModal");
  };

  const handleActionConvertUser = email => {
    managerState.setKeyValue("activeEmail", email);
    managerState.setKeyValue("openModalConvertUser", true);
  };

  const handleActionEditUser = email => {
    managerState.setKeyValue("activeEmail", email);

    if (isOrganizationOneascent(managerState.organization?.name)) {
      managerState.openModal("addProspectModal");
    } else {
      managerState.openModal("leadDetailsModal");
    }
  };

  const handleActionNewIpq = email => {
    managerState.setKeyValue("activeEmail", email);
    managerState.setKeyValue("newIpqMode", true);
    managerState.setKeyValue("openModalProposalGuide", true);
  };

  const handleActionViewIpq = email => {
    managerState.setKeyValue("activeEmail", email);
    managerState.setKeyValue("openModalViewIpq", true);
  };

  const handleCreateProposalButton = email => {
    const proposalData = managerState?.managedUsers?.find(
      it => it.email === email
    );

    if (
      !proposalData?.valueMap?.guideInitialData ||
      proposalData?.valueMap?.guideInitialData?.enableOnboardingQuestions
    ) {
      managerState.setKeyValue("activeEmail", email);
      managerState.setKeyValue("openModalProposalGuide", true);
    } else {
      openProposalWithConfig({
        organization: managerState?.organization?.name,
        prospectType: managerState?.onboardingQuestionnaireType,
        userEmail: email,
      });
    }
  };

  const getTableData = () =>
    managerState.getProspectUsers().map(it => ({
      assets: it.valueMap?.productMap
        ? Object.keys(it.valueMap.productMap)
            .map(
              key =>
                it.valueMap.productMap[key][
                  it.valueMap.productMap[key].length - 1
                ].investmentAmount
            )
            .reduce((acc, value) => acc + value, 0)
        : (it.proposedAmount ?? 0),
      email: it.email,
      key: it.email,
      name: it.lastName + ", " + it.firstName,
      proposalData: getLastSavedProposalProps(it.valueMap?.productMap),
      proposalGenerated:
        it.proposalSavedDate?.$date ??
        getLastSavedProposalProps(it.valueMap.productMap)?.timestamp,
      prospectObjective: it.valueMap?.prospectObjective,
    }));

  return (
    <StylesContainer>
      <Row>
        <Input
          style={{
            borderRadius: 25,
            height: 37,
            marginBottom: 16,
            maxWidth: 290,
            textTransform: "capitalize",
          }}
          placeholder={`Search ${instanceLabel}s...`}
          onChange={event => setSearchedText(event.target.value)}
        />
      </Row>
      <Table
        dataSource={getTableData()}
        columns={getColumns({
          handleActionArchiveClient,
          handleActionConvertUser,
          handleActionEditUser,
          handleActionNewIpq,
          handleActionViewIpq,
          handleCreateProposalButton,
          instanceLabel,
          managerState,
          searchedText,
          token,
        })}
        pagination={false}
        loading={managerState.loading}
      />

      <ConvertClientDialog
        email={managerState.activeEmail}
        handleClose={() =>
          managerState.setKeyValue("openModalConvertUser", false)
        }
        open={managerState.openModalConvertUser}
      />
      <ModalArchiveManagedUser
        instanceName={instanceLabel}
        loading={managerState.loading}
        onCancel={() => managerState.closeModal("archiveManagedUserModal")}
        onConfirm={handleArchiveClient}
        open={managerState.archiveManagedUserModal}
      />
      <ModalViewIpq
        activeUserData={managerState.managedUsers?.find(
          it => it.email === managerState.activeEmail
        )}
        onCancel={() => managerState.setKeyValue("openModalViewIpq", false)}
        open={managerState.openModalViewIpq}
      />
    </StylesContainer>
  );
};

export default ProspectsTable;
