import styled from "styled-components";

const StylesContainer = styled.div`
  .header {
    border-bottom: 1px solid #c9c8c8;
    padding: 23px 63px 17px 35px;

    .title {
      font-size: 20px;
      font-weight: 800;
      color: #1b5568;
      margin: 0;
    }
  }

  .body {
    padding: 42px 78px 27px 35px;
  }

  .ant-tabs {
    .ant-tabs-tab-btn {
      font-size: 16px;
    }

    .ant-tabs-nav {
      margin-bottom: 30px;

      &::before {
        bottom: 3px;
        border-bottom-color: #c9c8c8;
      }

      .ant-tabs-ink-bar {
        height: 8px;
        background: #02494e;
        border-radius: 11px;
      }
    }
  }

  .ant-table-wrapper .ant-table-thead > tr > * {
    font-weight: 500;
    background: transparent;

    &::before {
      display: none;
    }
  }
`;

export default StylesContainer;
