import styled from "styled-components";

const StylesContainer = styled.div`
  .ant-table-wrapper tr > td:nth-child(3),
  .ant-table-wrapper tr > th:nth-child(3),
  .ant-table-wrapper
    .ant-table-tbody
    > tr.ant-table-row:hover
    > td:nth-child(3) {
    background: #6dc1c730;
  }
`;

export default StylesContainer;
