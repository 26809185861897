import { Flex, Image } from "antd";

import step_inti from "../../images/step_init.png";

const StepInit = () => (
  <Flex vertical>
    <Image preview={false} src={step_inti} />
    <div
      style={{
        fontSize: 19,
        lineHeight: "140%",
        padding: 30,
      }}
    >
      <p style={{ margin: "0 0 24px" }}>
        Welcome to the Planning Personality exercise! Below are five (5)
        questions, each with four (4) options. Please order each of these
        options from your most likely answer at the top, to your least likely
        answer at the bottom. Here are the ground rules:
      </p>
      <ol>
        <li>
          There are no right or wrong answers! With that said, feel free! You’re
          free to answer truthfully, not how you think or feel you ought to
          answer.
        </li>
        <li>
          Answer with what seems true of you today! Consider your current
          tendencies rather than where you once were or wish to be one day.
        </li>
        <li>
          After the quiz, you will be presented with some possible matches for
          your Planning Personality.
        </li>
      </ol>
    </div>
  </Flex>
);

export default StepInit;
