import { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import State from "../../../../../../context/admin/State";
import { Button, Flex, Input, Table } from "antd";
import MenuActions from "../../../../../../../../atoms/MenuActions";

import { StyledSwitch } from "./styles";

import { MAX_FEATURED_EVENTS_AMOUNT } from "../../../../../../constants";

import { updateEvent } from "../../../../../../requests/global";

import { SearchOutlined } from "@ant-design/icons";
import { ReactComponent as CirclePlusGreen } from "../../../../../../images/circle_plus_green.svg";

const getColumns = ({
  handleDelete,
  handleFeatured,
  handleUpdate,
  loading,
  searchedText,
}) => [
  {
    dataIndex: "name",
    filteredValue: [searchedText],
    key: "name",
    onFilter: (value, record) =>
      String(record.name).toLowerCase().includes(value.toLowerCase()) ||
      String(record.category).toLowerCase().includes(value.toLowerCase()) ||
      String(record.location).toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => a.name.localeCompare(b.name),
    title: "Event Name",
  },
  {
    dataIndex: "category",
    key: "category",
    sorter: (a, b) => a.category.localeCompare(b.category),
    title: "Category",
  },
  {
    dataIndex: "date",
    key: "date",
    render: value => dayjs(value).format("MMMM D, YYYY"),
    sorter: (a, b) => a.date.localeCompare(b.date),
    title: "Dates",
  },
  {
    dataIndex: "location",
    key: "location",
    sorter: (a, b) => a.location.localeCompare(b.location),
    title: "Location",
  },
  {
    dataIndex: "linkUrl",
    key: "linkUrl",
    render: value => (
      <div
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          width: 300,
          whiteSpace: "nowrap",
        }}
      >
        <a
          href={value?.includes("https://") ? value : "https://" + value}
          target={"_blank"}
          rel={"noreferrer"}
        >
          <u>{value}</u>
        </a>
      </div>
    ),
    sorter: (a, b) => a.linkUrl?.localeCompare(b.linkUrl),
    title: "URL",
  },
  {
    dataIndex: "featured",
    key: "featured",
    render: (checked, restValues) => (
      <Flex justify={"center"}>
        <StyledSwitch
          checked={checked}
          disabled={loading}
          onChange={checked => handleFeatured(restValues._id, checked)}
          size={"small"}
        />
      </Flex>
    ),
    sorter: false,
    title: "Featured",
  },
  {
    dataIndex: "order",
    key: "order",
    sorter: (a, b) => (a.order ?? 0) - (b.order ?? 0),
    title: "Order",
  },
  {
    dataIndex: "graphicFile",
    key: "graphicFile",
    render: value =>
      value && (
        <a href={value.url} target={"_blank"} rel={"noreferrer"}>
          <u>{value.filename}</u>
        </a>
      ),
    sorter: (a, b) => a.graphicFile?.url?.localeCompare(b.graphicFile?.url),
    title: "Graphic",
  },
  {
    className: "actions-menu-table-cell",
    dataIndex: "actionsMenu",
    key: "actionsMenu",
    render: (value, restValues) => (
      <MenuActions
        items={getItems({
          handleDelete,
          eventId: restValues._id,
          handleUpdate,
        })}
      />
    ),
    title: "",
    width: 40,
  },
];

const getItems = ({ handleDelete, eventId, handleUpdate }) => [
  {
    key: "1",
    label: <div onClick={() => handleUpdate(eventId)}>Edit Event</div>,
  },
  {
    key: "2",
    label: <div onClick={() => handleDelete(eventId)}>Delete Event</div>,
  },
];

const TabEvents = () => {
  const [adminState] = useContext(State);
  const [loading, setLoading] = useState(false);
  const [searchedText, setSearchedText] = useState("");

  useEffect(() => {
    setLoading(true);
    adminState.reFetch.events().then(() => setLoading(false));
  }, []);

  const handleDelete = eventId => {
    adminState.setKeyValue("activeEventId", eventId);
    adminState.openModal("deleteEventModal");
  };

  const handleFeatured = (eventId, checked) => {
    if (checked) {
      const featuredEventsQuantity = adminState.events.reduce(
        (acc, it) => (it.featured ? ++acc : acc),
        0
      );

      if (featuredEventsQuantity >= MAX_FEATURED_EVENTS_AMOUNT) {
        adminState.showWarning(
          `A maximum of ${MAX_FEATURED_EVENTS_AMOUNT} events can be featured at once. Please disable an event.`
        );

        return;
      }
    }

    const eventData = adminState.events.find(it => it._id === eventId);

    if (eventData) {
      adminState.showLoader();

      updateEvent({
        eventId,
        payloadData: {
          ...eventData,
          featured: checked,
        },
      })
        .then(() => {
          adminState.reFetch.events().then(() => {
            adminState.showSuccess("Event updated");
          });
        })
        .catch(error => adminState.showWarning(error.message))
        .finally(() => adminState.hideLoader());
    } else {
      adminState.showWarning("Event do not exist");
    }
  };

  const handleUpdate = eventId => {
    adminState.setKeyValue("activeEventId", eventId);
    adminState.openModal("eventModal");
  };

  return (
    <Flex vertical gap={20}>
      <Flex justify={"space-between"}>
        <Input
          className={"pointer"}
          onChange={event => setSearchedText(event.target.value)}
          placeholder="Search..."
          size="large"
          style={{ width: 236, borderRadius: 23 }}
          suffix={<SearchOutlined style={{ fontSize: 20, color: "#B1B1B1" }} />}
        />
        <Button
          shape={"round"}
          size={"small"}
          style={{ color: "#2E9642" }}
          type={"text"}
          onClick={() => adminState.openModal("eventModal")}
        >
          <Flex align={"center"} gap={8}>
            <CirclePlusGreen />
            <span style={{ fontFamily: "Poppins, sans-serif" }}>New Event</span>
          </Flex>
        </Button>
      </Flex>
      <Table
        columns={getColumns({
          handleDelete,
          handleFeatured,
          handleUpdate,
          loading: adminState.loading,
          searchedText,
        })}
        dataSource={adminState.events?.map((it, index) => ({
          ...it,
          key: index,
        }))}
        loading={loading}
        pagination={{
          position: ["none", "bottomCenter"],
        }}
        size={"small"}
      />
    </Flex>
  );
};

export default TabEvents;
