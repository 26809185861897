import dayjs from "dayjs";

import {
  capitalizeString,
  getCurrencyValue,
  getImageDataURL,
  getPercentValue,
  setPhoneMask,
} from "../../../utils/helpers/general";

export const getDataForAstorProposalPdf = ({
  barsChartSvg,
  growthChartSvg,
  pieChartSvg,
  pieChartWithLegendSvg,
  proposalData,
  userManagerData,
  userName,
}) => {
  if (!proposalData) return;

  let barsChartB64 = getImageDataURL(barsChartSvg);
  let growthChartB64 = getImageDataURL(growthChartSvg);
  let pieChartB64 = getImageDataURL(pieChartSvg);
  let pieChartWithLegendSvgB64 = getImageDataURL(pieChartWithLegendSvg);

  const requestBody = JSON.stringify({
    advisorEmail: userManagerData._id,
    advisorFee: proposalData.advisorFee ?? "1%",
    advisorFullName:
      userManagerData.personalInfo.firstName +
      " " +
      userManagerData.personalInfo.lastName,
    beginWithdrawal: proposalData.beginWithdrawal,
    advisorState: userManagerData.personalInfo.state ?? "",
    advisorStreet: userManagerData.personalInfo.street ?? "",
    advisorZipCode: userManagerData.personalInfo.zipCode ?? "",
    advisorFirm: userManagerData.firmName,
    advisorPhone: setPhoneMask(userManagerData.personalInfo.phoneNumber),
    contributions: getCurrencyValue(proposalData.contributions),
    currentAgeRange: proposalData.currentAgeRange,
    date: dayjs(proposalData.timestamp).format("MM/DD/YYYY"),
    growthChart: growthChartB64,
    householdIncome: getCurrencyValue(proposalData.householdIncome),
    incomePieChart: pieChartB64,
    incomePieChartWithLegend: pieChartWithLegendSvgB64,
    incomeSelectionChart: barsChartB64,
    initialInvestment: getCurrencyValue(proposalData.investmentAmount),
    insufficientFundsStartingIn: proposalData.insufficientFundsStartingIn,
    investmentAmount: getCurrencyValue(proposalData.investmentAmount),
    investmentDuration: proposalData.investmentDuration,
    investmentObjective: capitalizeString(proposalData.investmentObjective),
    lengthOfInvestment:
      proposalData.investmentDuration === "20-"
        ? "20+"
        : (proposalData.investmentDuration ?? proposalData.lengthOfInvestment),
    productName: proposalData.productName,
    prospectType: proposalData.prospectType,
    riskTolerance: capitalizeString(proposalData.riskTolerance),
    userName,
    withdrawalAmount:
      getCurrencyValue(proposalData.calculated?.withdrawalAmount) ?? 0,
    withdrawalRate: getPercentValue(proposalData.withdrawalLevel ?? 5),
    yearToStartWithdrawals: proposalData.yearToStartWithdrawals,
  });

  localStorage.setItem("personalizedProposalData", requestBody);
};
