import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { Col, Row, Tabs, theme } from "antd";
import PortfolioHoldings from "./components/PortfolioHoldings";

import { ONEASCENT_ELEVATE_STORIES_LINK } from "../../../../ecosystems/oneascent/constants";

const StyledTabs = styled(Tabs)`
  &.ant-tabs
    > .ant-tabs-content-holder
    > .ant-tabs-content
    > .ant-tabs-tabpane {
    height: 100%;
  }

  &.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
    padding-top: 30px;
  }

  &.ant-tabs .ant-tabs-ink-bar {
    width: 3px;
    background: #4897d1;
  }

  &.ant-tabs > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane,
  .ant-tabs
    > div
    > .ant-tabs-content-holder
    > .ant-tabs-content
    > .ant-tabs-tabpane {
    padding: 32px 32px 0;
  }

  @media (max-width: 991px) {
    &.ant-tabs
      > .ant-tabs-content-holder
      > .ant-tabs-content
      > .ant-tabs-tabpane,
    .ant-tabs
      > div
      > .ant-tabs-content-holder
      > .ant-tabs-content
      > .ant-tabs-tabpane {
      padding: 0;
    }
  }
`;

const XRayTab = ({ product, xRayList }) => {
  const { token } = theme.useToken();
  const isSmallScreen = useMediaQuery({ maxWidth: token.screenMDMax });

  return (
    <Row>
      <Col span={24}>
        <StyledTabs
          defaultActiveKey="portfolio_holdings"
          tabPosition={isSmallScreen ? "top" : "left"}
          items={[
            {
              key: "portfolio_holdings",
              label: "Portfolio Holdings",
              children: (
                <PortfolioHoldings
                  product={product}
                  xRayData={xRayList[product?.riskTolerance?.toLowerCase()]}
                />
              ),
            },
            {
              key: "elevate_stories",
              label: "Elevate Stories",
              children: (
                <iframe
                  style={{ width: "100%", height: "70vh" }}
                  src={ONEASCENT_ELEVATE_STORIES_LINK}
                />
              ),
            },
          ]}
        />
      </Col>
    </Row>
  );
};

export default XRayTab;
