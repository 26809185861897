const IconMarketing = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.9062 6.41094H11.1934L8.67324 4.00039C8.64118 3.97037 8.599 3.9535 8.55508 3.95312H3.09375C2.71348 3.95312 2.40625 4.26035 2.40625 4.64062V17.3594C2.40625 17.7396 2.71348 18.0469 3.09375 18.0469H18.9062C19.2865 18.0469 19.5938 17.7396 19.5938 17.3594V7.09844C19.5938 6.71816 19.2865 6.41094 18.9062 6.41094ZM18.0469 16.5H3.95312V5.5H8.00293L10.5725 7.95781H18.0469V16.5Z"
      fill="currentColor"
    />
  </svg>
);

export default IconMarketing;
