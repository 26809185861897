import StylesContainer from "./styles";

import { ReactComponent as AddIcon } from "../../images/plus_blue.svg";

const CompareCardPlaceholder = ({ handleClick }) => (
  <StylesContainer align={"middle"} justify={"center"} onClick={handleClick}>
    <AddIcon />
  </StylesContainer>
);

export default CompareCardPlaceholder;
