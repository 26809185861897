const IconClient = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.4439 16.4055C18.0386 15.4455 17.4505 14.5735 16.7123 13.8381C15.9763 13.1005 15.1044 12.5125 14.1449 12.1064C14.1363 12.1021 14.1277 12.1 14.1191 12.0957C15.4576 11.1289 16.3277 9.5541 16.3277 7.77734C16.3277 4.83398 13.9429 2.44922 10.9996 2.44922C8.05621 2.44922 5.67144 4.83398 5.67144 7.77734C5.67144 9.5541 6.54156 11.1289 7.88003 12.0979C7.87144 12.1021 7.86285 12.1043 7.85425 12.1086C6.89175 12.5146 6.02808 13.0969 5.28687 13.8402C4.54933 14.5762 3.96129 15.4481 3.55523 16.4076C3.15631 17.347 2.94117 18.3542 2.92144 19.3746C2.92087 19.3975 2.92489 19.4204 2.93327 19.4417C2.94165 19.4631 2.95422 19.4825 2.97024 19.4989C2.98625 19.5153 3.0054 19.5284 3.02653 19.5373C3.04767 19.5462 3.07038 19.5508 3.09332 19.5508H4.38238C4.47691 19.5508 4.5521 19.4756 4.55425 19.3832C4.59722 17.7246 5.26324 16.1713 6.44058 14.9939C7.65875 13.7758 9.27652 13.1055 10.9996 13.1055C12.7226 13.1055 14.3404 13.7758 15.5586 14.9939C16.7359 16.1713 17.4019 17.7246 17.4449 19.3832C17.447 19.4777 17.5222 19.5508 17.6168 19.5508H18.9058C18.9288 19.5508 18.9515 19.5462 18.9726 19.5373C18.9937 19.5284 19.0129 19.5153 19.0289 19.4989C19.0449 19.4825 19.0575 19.4631 19.0659 19.4417C19.0742 19.4204 19.0783 19.3975 19.0777 19.3746C19.0562 18.3477 18.8435 17.3486 18.4439 16.4055V16.4055ZM10.9996 11.4727C10.0134 11.4727 9.08531 11.0881 8.38707 10.3898C7.68882 9.6916 7.30425 8.76348 7.30425 7.77734C7.30425 6.79121 7.68882 5.86309 8.38707 5.16484C9.08531 4.4666 10.0134 4.08203 10.9996 4.08203C11.9857 4.08203 12.9138 4.4666 13.6121 5.16484C14.3103 5.86309 14.6949 6.79121 14.6949 7.77734C14.6949 8.76348 14.3103 9.6916 13.6121 10.3898C12.9138 11.0881 11.9857 11.4727 10.9996 11.4727Z"
      fill="currentColor"
    />
  </svg>
);

export default IconClient;
