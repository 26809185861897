import { useMediaQuery } from "react-responsive";
import { Flex, theme } from "antd";

import StylesContainer from "./styles";

import { PENNY_TIPS_LIST } from "../../../../utils/constants";

import { ReactComponent as ArrowRight } from "../../images/icon_arrow_right.svg";

const PennyTips = ({ handlePennyTipClick }) => {
  const { token } = theme.useToken();
  const isSmallScreen = useMediaQuery({ maxWidth: token.screenMDMax });

  return (
    <StylesContainer gap={isSmallScreen ? 10 : 31} vertical>
      <h2 className={"title"}>Let our AI help answer your money questions</h2>
      <Flex justify={"center"}>
        <div className={"divider"} />
      </Flex>
      <Flex gap={16} vertical>
        {PENNY_TIPS_LIST.map((it, index) => (
          <Flex
            align={"center"}
            className={"card"}
            gap={6}
            justify={"space-between"}
            onClick={() => handlePennyTipClick(it.title)}
            key={index}
          >
            <Flex gap={6} vertical>
              <h3 className={"title"}>{it.title}</h3>
              <p className={"description"}>{it.description}</p>
            </Flex>
            <Flex
              align={"center"}
              className={"icon-container"}
              justify={"center"}
            >
              <ArrowRight />
            </Flex>
          </Flex>
        ))}
      </Flex>
    </StylesContainer>
  );
};

export default PennyTips;
