import { useContext } from "react";
import State from "../../../../../../context/user/State";
import { Button, Flex } from "antd";
import ModalPerspectives from "../ModalPerspectives";
import ModalPriorities from "../ModalPriorities";

import StylesContainer from "./styles";

import { CheckOutlined } from "@ant-design/icons";

const SectionWorkspace = () => {
  const [userState] = useContext(State);

  const guideInitialData = userState.getPreferenceValue("guideInitialData");

  const handleLaunchPerspectives = perspectiveFor => {
    userState.setKeyValue("perspectiveFor", perspectiveFor);
    userState.setKeyValue("openModalPerspectives", true);
  };

  const handleLaunchPriorities = prioritiesFor => {
    userState.setKeyValue("prioritiesFor", prioritiesFor);
    userState.setKeyValue("openModalPriorities", true);
  };

  const handlePerspectivesComplete = perspectives => {
    userState.setKeyValue("loading", true);

    userState
      .setPreferenceValue(userState.perspectiveFor, perspectives)
      .then(() => {
        userState.showSuccess("Perspectives saved");
      })
      .catch(error => console.log(error))
      .finally(() => userState.setKeyValue("loading", false));
  };

  const handlePrioritiesComplete = priorities => {
    userState.setKeyValue("loading", true);

    userState
      .setPreferenceValue(userState.prioritiesFor, priorities)
      .then(() => {
        userState.showSuccess("Priorities saved");
      })
      .catch(error => console.log(error))
      .finally(() => userState.setKeyValue("loading", false));
  };

  const handleViewPerspectiveCouple = () => {
    userState.setKeyValue("viewPerspectiveCoupleResults", true);
    userState.setKeyValue("showButtonBackToDashboard", true);
  };

  const handleViewPerspectivesResults = perspectiveType => {
    userState.setKeyValue("viewPerspectiveIndividualResults", perspectiveType);
    userState.setKeyValue("showButtonBackToDashboard", true);
  };

  const handleViewPrioritiesCouple = () => {
    userState.setKeyValue("viewPrioritiesCoupleResults", true);
    userState.setKeyValue("showButtonBackToDashboard", true);
  };

  const ButtonViewPerspectivesMy = () =>
    userState.getPreferenceValue("perspectiveMy") ? (
      <Button
        icon={<CheckOutlined />}
        onClick={() => handleViewPerspectivesResults("perspectiveMy")}
        shape={"round"}
        style={{ background: "#209F00" }}
        type={"custom"}
      >
        View {userState.personalInfo?.firstName}'s
      </Button>
    ) : (
      <Button
        onClick={() => handleLaunchPerspectives("perspectiveMy")}
        shape={"round"}
        type={"custom"}
      >
        <span>
          Launch {userState.personalInfo?.firstName}’s
          <br />
          {"<"} 2 mins
        </span>
      </Button>
    );

  const ButtonViewPerspectivesSpouse = () =>
    userState.getPreferenceValue("perspectiveSpouse") ? (
      <Button
        icon={<CheckOutlined />}
        onClick={() => handleViewPerspectivesResults("perspectiveSpouse")}
        shape={"round"}
        style={{ background: "#209F00" }}
        type={"custom"}
      >
        View {guideInitialData?.spouseFirstName}’s
      </Button>
    ) : (
      <Button
        onClick={() => handleLaunchPerspectives("perspectiveSpouse")}
        shape={"round"}
        type={"custom"}
      >
        Launch {guideInitialData?.spouseFirstName}’s
      </Button>
    );

  const ButtonViewPerspectivesCouple = () =>
    userState.getPreferenceValue("perspectiveMy")?.selectedPersonality &&
    userState.getPreferenceValue("perspectiveSpouse")?.selectedPersonality ? (
      <Button
        icon={<CheckOutlined />}
        onClick={handleViewPerspectiveCouple}
        shape={"round"}
        style={{ background: "#209F00" }}
        type={"custom"}
      >
        View {userState.personalInfo?.lastName}'s
      </Button>
    ) : (
      <Button disabled shape={"round"} type={"custom"}>
        View {userState.personalInfo?.lastName}'s
      </Button>
    );

  const ButtonViewPrioritiesMy = () =>
    userState.getPreferenceValue("prioritiesMy") ? (
      <Button
        icon={<CheckOutlined />}
        onClick={() => handleLaunchPriorities("prioritiesMy")}
        shape={"round"}
        style={{ background: "#209F00" }}
        type={"custom"}
      >
        View {userState.personalInfo?.firstName}’s
      </Button>
    ) : (
      <Button
        onClick={() => handleLaunchPriorities("prioritiesMy")}
        shape={"round"}
        type={"custom"}
      >
        <span>
          Launch {userState.personalInfo?.firstName}’s
          <br />
          {"<"} 2 mins
        </span>
      </Button>
    );

  const ButtonViewPrioritiesSpouse = () =>
    userState.getPreferenceValue("prioritiesSpouse") ? (
      <Button
        icon={<CheckOutlined />}
        onClick={() => handleLaunchPriorities("prioritiesSpouse")}
        shape={"round"}
        style={{ background: "#209F00" }}
        type={"custom"}
      >
        View {guideInitialData?.spouseFirstName}’s
      </Button>
    ) : (
      <Button
        onClick={() => handleLaunchPriorities("prioritiesSpouse")}
        shape={"round"}
        type={"custom"}
      >
        Launch {guideInitialData?.spouseFirstName}’s
      </Button>
    );

  const ButtonViewPrioritiesCouple = () =>
    userState.getPreferenceValue("prioritiesFamily") ? (
      <Button
        icon={<CheckOutlined />}
        onClick={handleViewPrioritiesCouple}
        shape={"round"}
        style={{ background: "#209F00" }}
        type={"custom"}
      >
        View {userState.personalInfo?.lastName}'s
      </Button>
    ) : (
      <Button
        disabled={
          !userState.getPreferenceValue("prioritiesMy") ||
          !userState.getPreferenceValue("prioritiesSpouse")
        }
        onClick={handleViewPrioritiesCouple}
        shape={"round"}
        type={"custom"}
      >
        Launch {userState.personalInfo?.lastName}'s
      </Button>
    );

  return (
    <StylesContainer gap={10} vertical>
      <h3 className={"title"}>Welcome, {userState.personalInfo?.firstName}</h3>
      <Flex gap={29} style={{ height: "100%", width: "100%" }}>
        <Flex gap={10} style={{ width: "100%" }} vertical>
          <Flex
            align={"center"}
            className={"container-placeholder"}
            justify={"center"}
            style={{ height: 300, width: "100%" }}
          >
            <div>Message from OneAscent</div>
          </Flex>
          <h3 className={"title"}>Next up...</h3>
          <Flex gap={32} wrap={"wrap"}>
            <Flex align={"center"} className={"card-next-up"} gap={24} vertical>
              <h5 className={"title"}>Eliminate & Elevate</h5>
              <p className={"description"}>
                Your investments matter - let us know what to invest in, and
                what not to invest in.
                <br />
                <br /> Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse tempus ac nisi nec euismod. Praesent venenatis
                semper facilisis.
              </p>
              <Button disabled shape={"round"} type={"custom"}>
                Launch
              </Button>
            </Flex>
            <Flex align={"center"} className={"card-next-up"} gap={24} vertical>
              <h5 className={"title"}>Perspectives</h5>
              <p className={"description"}>
                We’re all uniquely made. How do you think and feel about money
                today?
              </p>
              <ButtonViewPerspectivesMy />
              {guideInitialData?.isMarried && (
                <>
                  <ButtonViewPerspectivesSpouse />
                  <ButtonViewPerspectivesCouple />
                </>
              )}
            </Flex>
            <Flex align={"center"} className={"card-next-up"} gap={24} vertical>
              <h5 className={"title"}>Priorities</h5>
              <p className={"description"}>
                Our why helps us stay on track. What matters most to you?
              </p>
              <ButtonViewPrioritiesMy />
              {guideInitialData?.isMarried && (
                <>
                  <ButtonViewPrioritiesSpouse />
                  <ButtonViewPrioritiesCouple />
                </>
              )}
            </Flex>
          </Flex>
        </Flex>
        <Flex
          align={"center"}
          className={"container-placeholder"}
          justify={"center"}
          style={{ width: 330 }}
        >
          <div>Resources & News...</div>
        </Flex>
      </Flex>

      <ModalPerspectives
        handleComplete={handlePerspectivesComplete}
        handleViewResults={handleViewPerspectivesResults}
        onCancel={() => userState.setKeyValue("openModalPerspectives", false)}
        open={userState.openModalPerspectives}
      />
      <ModalPriorities
        handleComplete={handlePrioritiesComplete}
        initialState={userState.getPreferenceValue(userState.prioritiesFor)}
        onCancel={() => userState.setKeyValue("openModalPriorities", false)}
        open={userState.openModalPriorities}
      />
    </StylesContainer>
  );
};

export default SectionWorkspace;
