import { useContext, useState } from "react";
import dayjs from "dayjs";
import State from "../../../../../../context/manager/State";
import { ConfigProvider, Input, Rate, Table } from "antd";
import MenuActions from "../../../../../../atoms/MenuActions";

import { HiddenFieldContainer, QuestionButton } from "./styles";

import { updateLead } from "../../../../../../utils/requests/manager";
import { setPhoneMask } from "../../../../../../utils/helpers/general";

import { SearchOutlined } from "@ant-design/icons";
import { ReactComponent as CloudIcon } from "../../images/cloud.svg";
import { ReactComponent as IntroductionCheckmark } from "./images/introduction_checkmark.svg";

const getColumns = ({
  handleLeadDataChange,
  handleMoveLeadToClient,
  handleReleaseLead,
  handleViewQuestion,
  managerAccesses,
  searchedText,
}) => [
  {
    dataIndex: "firstName",
    filteredValue: [searchedText],
    key: "leadName",
    render: (value, restValues) =>
      restValues.valueMap?.leadInitial?.hideLeadContact ? (
        <span style={{ fontWeight: 500, color: "#121212" }}>
          Next Lead #???
        </span>
      ) : (
        `${restValues.firstName} ${restValues.lastName}`
      ),
    onFilter: (value, record) =>
      String(record.firstName).toLowerCase().includes(value.toLowerCase()) ||
      String(record.lastName).toLowerCase().includes(value.toLowerCase()) ||
      String(record.email).toLowerCase().includes(value.toLowerCase()),
    title: "Lead Name",
  },
  {
    dataIndex: "question",
    key: "question",
    render: (value, restValues) => (
      <QuestionButton
        onClick={() => handleViewQuestion(restValues.email)}
        shape={"round"}
        size={"small"}
      >
        <CloudIcon />
        View
      </QuestionButton>
    ),
    title: "Question",
  },
  {
    dataIndex: "email",
    key: "email",
    render: (value, restValues) =>
      restValues.valueMap?.leadInitial?.hideLeadContact ? (
        <HiddenFieldContainer />
      ) : (
        <a href={"mailto:" + value}>{value}</a>
      ),
    title: "Email",
  },
  {
    dataIndex: "phoneNumber",
    key: "phoneNumber",
    render: (value, restValues) =>
      restValues.valueMap?.leadInitial?.hideLeadContact ? (
        <HiddenFieldContainer />
      ) : (
        setPhoneMask(value)
      ),
    title: "Phone",
  },
  {
    dataIndex: "valueMap",
    key: "rate",
    render: (it, restValues) => (
      <Rate
        onChange={value =>
          handleLeadDataChange({
            key: "rate",
            value,
            email: restValues.email,
            initialState: restValues.valueMap?.leadInitial,
          })
        }
        value={it?.leadInitial?.rate}
      />
    ),
    title: "Lead Ranking",
  },
  {
    align: "right",
    title: "Lead Received",
    dataIndex: "valueMap",
    key: "receivedDate",
    render: it => dayjs(it?.leadInitial?.createdAt).format("MM/DD/YYYY"),
  },
  {
    align: "center",
    dataIndex: "valueMap",
    key: "introduction",
    render: valueMap =>
      !valueMap?.leadInitial?.hideLeadContact && <IntroductionCheckmark />,
    title: "Introduction",
  },
  {
    className: "actions-menu-table-cell",
    dataIndex: "actionsMenu",
    key: "actionsMenu",
    render: (value, restValues) => (
      <MenuActions
        items={getItems({
          email: restValues.email,
          handleMoveLeadToClient,
          handleReleaseLead,
          managerAccesses,
        })}
      />
    ),
    title: "",
    width: 40,
  },
];

const getItems = ({
  email,
  handleMoveLeadToClient,
  handleReleaseLead,
  managerAccesses,
}) => {
  const showMoveToClientAction =
    managerAccesses.affiliate ||
    managerAccesses.level ||
    managerAccesses.proportal;

  return [
    {
      hidden: !showMoveToClientAction,
      key: "moveToClient",
      label: (
        <div onClick={() => handleMoveLeadToClient(email)}>Move to client</div>
      ),
    },
    {
      hidden: !showMoveToClientAction,
      type: "divider",
    },
    {
      key: "release",
      label: <div onClick={() => handleReleaseLead(email)}>Release</div>,
    },
  ].filter(it => !it.hidden);
};

const SectionNextLeads = ({ handleMoveLeadToClient }) => {
  const [managerState] = useContext(State);
  const [searchedText, setSearchedText] = useState("");

  const nextLeadsList = managerState.managedUsers
    ?.filter(
      it =>
        it.entryPoint === "addedByOrgManager" &&
        (it.userType === "Assigned" || it.userType === "Prospect") &&
        !it.valueMap?.leadInitial?.isConverted
    )
    ?.map((it, index) => ({ ...it, key: index }));

  const handleLeadDataChange = ({ email, initialState, key, value }) => {
    updateLead({
      email,
      payload: {
        ...initialState,
        [key]: value,
        updatedAt: new Date().getTime(),
      },
    })
      .then(() =>
        managerState
          .setAdminData()
          .then(() => managerState.showSuccess("Lead data updated"))
      )
      .catch(error => managerState.showError(error.response?.data));
  };

  const handleReleaseLead = email => {
    managerState.setKeyValue("activeEmail", email);
    managerState.openModal("releaseLeadModal");
  };

  const handleViewQuestion = email => {
    managerState.setKeyValue("activeEmail", email);
    managerState.openModal("leadQuestionModal");
  };

  return (
    <>
      <Input
        style={{
          maxWidth: 228,
          height: 40,
          borderRadius: 25,
          marginBottom: 27,
        }}
        placeholder="Search..."
        onChange={event => setSearchedText(event.target.value)}
        suffix={<SearchOutlined />}
      />
      <ConfigProvider
        theme={{
          components: {
            Table: {
              headerColor: "#6C6C6C",
              colorText: "#516474",
              rowHoverBg: "#DCE14725",
            },
            Switch: {
              colorPrimary: "#4F8083",
              colorPrimaryHover: "#4F8083",
            },
          },
        }}
      >
        <Table
          columns={getColumns({
            handleLeadDataChange,
            handleMoveLeadToClient,
            handleReleaseLead,
            handleViewQuestion,
            managerAccesses: managerState.access,
            searchedText,
          })}
          dataSource={nextLeadsList}
          loading={managerState.loading}
          pagination={false}
          scroll={{
            x: 1250,
          }}
        />
      </ConfigProvider>
    </>
  );
};

export default SectionNextLeads;
