import { useContext, useState } from "react";
import State from "../../context/manager/State";
import QuestionnaireContentAstor from "../../ecosystems/astor/components/QuestionnaireContentAstor";
import QuestionnaireContentDefault from "./components/QuestionnaireContentDefault";
import QuestionnaireContentIPQ from "../../ecosystems/oneascent/components/QuestionnaireContentIPQ";
import QuestionnaireContentRivershares from "../../ecosystems/rivershares/components/QuestionnaireContentRivershares";
import QuestionnaireContentWealt from "../../ecosystems/oneascent/components/QuestionnaireContentWealt";
import ModalDisclosures from "./components/ModalDisclosures";

import StyledModal from "./styles";

import { DEFAULT_BIRTH_YEAR } from "../../utils/constants";

import { openProposal } from "../../utils/requests/manager";
import { updateProductObjective } from "../../utils/requests/regularApp";
import {
  isOrganizationAstor,
  isOrganizationLevel,
  isOrganizationRivershares,
} from "../../utils/helpers/specialized";

const ModalProposalGuide = ({ email, open, handleClose }) => {
  const [managerState] = useContext(State);
  const [showDisclosuresModal, setShowDisclosuresModal] = useState(false);
  const organization = managerState.organization?.name ?? managerState.orgName;

  const handleCreateProposal = proposalData => {
    managerState.showLoader();
    handleClose();

    updateProductObjective({
      email,
      ...proposalData,
      birthYear:
        managerState.managedUsers?.find(it => it.email === email)?.birthYear ||
        proposalData.birthYear ||
        DEFAULT_BIRTH_YEAR,
    })
      .then(() => {
        managerState.setAdminData().then(() => {
          managerState.showSuccess("New proposal created!");
          managerState.hideLoader();
          openProposal({
            userEmail: email,
            organization: proposalData.organization,
          });
        });
      })
      .catch(error => {
        managerState.showError(error.response?.data);
        managerState.hideLoader();
      });
  };

  return (
    <StyledModal open={open} width="100vw" footer={false} closable={false}>
      {managerState.onboardingQuestionnaireType === "wealthManagement" && (
        <QuestionnaireContentWealt
          handleClose={handleClose}
          handleCreateProposal={handleCreateProposal}
          open={open}
          setShowDisclosuresModal={setShowDisclosuresModal}
        />
      )}
      {managerState.onboardingQuestionnaireType === "IPQ" && (
        <QuestionnaireContentIPQ
          handleCreateProposal={handleCreateProposal}
          onClose={handleClose}
          setShowDisclosuresModal={setShowDisclosuresModal}
        />
      )}
      {isOrganizationRivershares(organization) && (
        <QuestionnaireContentRivershares
          handleClose={handleClose}
          handleCreateProposal={handleCreateProposal}
          open={open}
        />
      )}
      {isOrganizationAstor(organization) && (
        <QuestionnaireContentAstor
          handleClose={handleClose}
          handleCreateProposal={handleCreateProposal}
          open={open}
        />
      )}
      {isOrganizationLevel(organization) && (
        <QuestionnaireContentDefault
          handleClose={handleClose}
          handleCreateProposal={handleCreateProposal}
          open={open}
        />
      )}

      <ModalDisclosures
        handleClose={() => setShowDisclosuresModal(false)}
        open={showDisclosuresModal}
      />
    </StyledModal>
  );
};

export default ModalProposalGuide;
