import { Flex, Image } from "antd";

import { Label, StyledInputNumber } from "./styles";

import {
  cleanCurrencyValue,
  getCurrencyValue,
  selectValue,
} from "../../../../../../utils/helpers/general";

import demo from "./images/demo.png";

const SectionBitAboutYou = ({
  ButtonsContainer,
  proposalData,
  updateProposalData,
}) => (
  <Flex gap={50} justify={"space-between"} wrap={"wrap"}>
    <div style={{ marginTop: 50, maxWidth: 500 }}>
      <h2 className="title" style={{ margin: "0 0 10px" }}>
        A bit about you...
      </h2>
      <p className="description" style={{ margin: "0 0 41px" }}>
        Let’s set some basic parameters for this journey. Please fill out the
        questions below.
      </p>
      <Flex gap={16} style={{ maxWidth: 400, marginBottom: 90 }} vertical>
        <Flex justify={"space-between"}>
          <Label>Amount</Label>
          <StyledInputNumber
            size="large"
            min={0}
            step={1000}
            value={proposalData.investmentAmount}
            formatter={getCurrencyValue}
            parser={cleanCurrencyValue}
            onChange={value => updateProposalData("investmentAmount", value)}
            onClick={selectValue}
          />
        </Flex>
        <Flex justify={"space-between"}>
          <Label>Time (In years)</Label>
          <StyledInputNumber
            size="large"
            min={1}
            max={80}
            step={1}
            value={proposalData.investmentDuration}
            onChange={value => updateProposalData("investmentDuration", value)}
            onClick={selectValue}
          />
        </Flex>
        <Flex justify={"space-between"}>
          <Label>Combined Household Income</Label>
          <StyledInputNumber
            size="large"
            min={0}
            step={100}
            value={proposalData.householdIncome}
            formatter={getCurrencyValue}
            parser={cleanCurrencyValue}
            onChange={value => updateProposalData("householdIncome", value)}
            onClick={selectValue}
          />
        </Flex>
        <Flex justify={"space-between"}>
          <Label>Monthly Investment Contributions</Label>
          <StyledInputNumber
            size="large"
            min={0}
            step={100}
            value={proposalData.contributions}
            formatter={getCurrencyValue}
            parser={cleanCurrencyValue}
            onChange={value => updateProposalData("contributions", value)}
            onClick={selectValue}
          />
        </Flex>
      </Flex>
      <ButtonsContainer />
    </div>
    <Image height={600} preview={false} src={demo} width={430} />
  </Flex>
);

export default SectionBitAboutYou;
