import { useContext, useState } from "react";
import State from "../../context/manager/State";
import {
  Button,
  Flex,
  Form,
  Input,
  Modal,
  Space,
  Switch,
  Typography,
} from "antd";

import { ValidationLabel } from "./styles";

import {
  changeOrgManagerPassword,
  changeUserManagerPassword,
  setManagerMFAEnabled,
} from "../../utils/requests/manager";
import { verifyPassword } from "../../utils/requests/regularApp";
import { isPortalAdmin } from "../../utils/helpers/specialized";

import Close from "../../icons/Close";

const ModalAdminSettings = () => {
  const [form] = Form.useForm();
  const [adminState] = useContext(State);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const onFinish = values => {
    adminState.showLoader();

    verifyPassword(oldPassword)
      .then(response => {
        if (response.data) {
          (isPortalAdmin()
            ? changeOrgManagerPassword
            : changeUserManagerPassword)(values)
            .then(() => {
              adminState.showSuccess("Password changed!");
              form.resetFields();
              adminState.hideLoader();
              adminState.closeModal("accountSettingsModal");
            })
            .catch(error => {
              console.log(error);
              adminState.hideLoader();
            });
        } else {
          adminState.hideLoader();
          adminState.showError("Old password is incorrect");
        }
      })
      .catch(error => {
        console.log(error);
        adminState.hideLoader();
      });
  };

  const toggleMFA = enabled => {
    setManagerMFAEnabled(enabled)
      .then(response => {
        console.log(response);
        adminState.setAdminData().then(() => {
          adminState.closeModal("accountSettingsModal");
          adminState.hideLoader();
          adminState.showSuccess("Your information has been saved. Thank you!");
        });
      })
      .catch(error => {
        console.log(error);
        adminState.hideLoader();
      });
  };

  return (
    <Modal
      closeIcon={<Close />}
      footer={false}
      onCancel={() => adminState.closeModal("accountSettingsModal")}
      open={adminState.accountSettingsModal}
      title="Settings"
    >
      <Flex style={{ padding: 40 }} vertical>
        <Form
          id={"adminSettingsForm"}
          onFinish={onFinish}
          style={{ display: "flex", flexDirection: "column", gap: 20 }}
          layout="vertical"
          autoComplete="off"
          form={form}
        >
          <Form.Item
            label="Old password"
            name="oldPassword"
            onChange={e => setOldPassword(e.target.value)}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                whitespace: false,
              },
            ]}
          >
            <Input.Password id="oldPasswordinput" size="large" />
          </Form.Item>
          <Form.Item
            label="New password"
            name="newPassword"
            onChange={e => setNewPassword(e.target.value)}
            style={{ margin: 0 }}
            hasFeedback
            rules={[
              {
                required: true,
                pattern:
                  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9#?!@$%^&*-]).{8,}$/,
                message: () => (
                  <Space direction="vertical" style={{ marginTop: 4 }} size={2}>
                    {!/[a-z]/.test(newPassword) && (
                      <ValidationLabel span={24}>
                        One lowercase character
                      </ValidationLabel>
                    )}
                    {!/[A-Z]/.test(newPassword) && (
                      <ValidationLabel span={24}>
                        One uppercase character
                      </ValidationLabel>
                    )}
                    {newPassword?.length < 8 && (
                      <ValidationLabel span={24}>
                        8 characters minimum
                      </ValidationLabel>
                    )}
                    {(!newPassword ||
                      !/^(?=.*[0-9])|(?=.*[^0-9A-Za-z]).*$/.test(
                        newPassword
                      )) && (
                      <ValidationLabel span={24}>
                        One number or one special character
                      </ValidationLabel>
                    )}
                  </Space>
                ),
                whitespace: false,
              },
            ]}
          >
            <Input.Password id="newPasswordInput " size="large" />
          </Form.Item>
          <Form.Item
            name="confirmNewPassword"
            label="Confirm Password"
            dependencies={["newPassword"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The new password that you entered do not match!")
                  );
                },
              }),
            ]}
          >
            <Input.Password id="confirmNewPasswordInput" size="large" />
          </Form.Item>
          <Button
            type="primary"
            shape="round"
            htmlType="submit"
            disabled={!oldPassword || adminState.loading}
          >
            Set New Password
          </Button>
        </Form>
        <Flex gap={4} style={{ marginTop: 40 }}>
          <Switch
            defaultChecked={adminState.isMfaEnabled}
            onChange={toggleMFA}
          />
          <Typography.Text>
            Enable Mfa (Multi-Factor Authentication)
          </Typography.Text>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default ModalAdminSettings;
