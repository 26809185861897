import { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import State from "../../../../../../context/manager/State";
import { Button, Input, Row, Space, Table, theme, Typography } from "antd";
import AnalyticValue from "../../../../../../atoms/AnalyticValue";

import { openProposal } from "../../../../../../utils/requests/manager";
import {
  getCurrencyValue,
  getCurrencyValueWithRoundSign,
} from "../../../../../../utils/helpers/general";
import {
  getLatestSavedProposalProps,
  getManagedUsersTotalProposedAmount,
} from "../../../../../../utils/helpers/specialized";

const getColumns = ({ adminState, searchedText }) => [
  {
    title: "Client Name",
    dataIndex: "clientName",
    key: "clientName",
    sorter: (a, b) => a.clientName.localeCompare(b.clientName),
    render: (text, restValues) =>
      restValues.proposalCreated ? (
        <a
          onClick={() =>
            openProposal({
              userEmail: restValues.email,
              organization: adminState.organization?.name,
            })
          }
        >
          {text}
        </a>
      ) : (
        text
      ),
    filteredValue: [searchedText],
    onFilter: (value, record) =>
      String(record.clientName).toLowerCase().includes(value.toLowerCase()) ||
      String(record.email).toLowerCase().includes(value.toLowerCase()),
  },
  {
    dataIndex: "email",
    key: "email",
    render: value => <a href={"mailto:" + value}>{value}</a>,
    sorter: (a, b) => a.email.localeCompare(b.email),
    title: "Email",
  },
  {
    title: "Product",
    dataIndex: "product",
    key: "product",
    sorter: (a, b) => a.product.localeCompare(b.product),
  },
  {
    title: "Proposal",
    dataIndex: "proposal",
    key: "proposal",
    sorter: (a, b) => a.proposal.localeCompare(b.proposal),
  },
  {
    title: "Proposal Created",
    dataIndex: "proposalCreated",
    key: "proposalCreated",
    sorter: (a, b) => dayjs(a.proposalCreated) - dayjs(b.proposalCreated),
  },
];

const AdvisorTableContainer = ({ advisorEmail, setActiveAdvisorEmail }) => {
  const { token } = theme.useToken();
  const [adminState] = useContext(State);
  const [searchedText, setSearchedText] = useState("");
  const [advisorData, setAdvisorData] = useState();

  useEffect(() => {
    adminState.managedUserManagers &&
      setAdvisorData(
        adminState.managedUserManagers.find(it => it.email === advisorEmail)
      );
  }, [adminState]);

  const getAdvisorTableData = () => {
    if (adminState.managedUserManagers) {
      return advisorData?.userManager?.managedUsers
        ?.filter(
          it =>
            !it.isDeleted &&
            (it.userType === "Registered" ||
              it.userType === "ConvertedProspect")
        )
        ?.map(it => {
          const latestSavedProposalProps = getLatestSavedProposalProps(
            it.valueMap?.productMap
          );

          return {
            key: it.email,
            clientName: it.firstName + " " + it.lastName,
            email: it.email,
            product:
              latestSavedProposalProps?.productName ?? it.selectedProduct,
            proposal: getCurrencyValue(
              it.valueMap?.productMap
                ? Object.keys(it.valueMap.productMap)
                    .map(
                      key =>
                        it.valueMap.productMap[key][
                          it.valueMap.productMap[key].length - 1
                        ].investmentAmount
                    )
                    .reduce((acc, value) => acc + value, 0)
                : (it.proposedAmount ?? 0)
            ),
            proposalCreated: dayjs(
              latestSavedProposalProps?.timestamp ?? it.proposalSavedDate?.$date
            ).format("MM/DD/YYYY"),
            actionsMenu: { email: it.email },
            proposalData: latestSavedProposalProps,
          };
        });
    }
  };

  const getAdvisorClients = () =>
    advisorData?.userManager?.managedUsers?.filter(
      it =>
        !it.isDeleted &&
        (it.userType === "Registered" || it.userType === "ConvertedProspect")
    ) ?? [];

  return (
    <>
      <Row>
        <Button
          type="text"
          shape="round"
          style={{
            color: token.colorSecondaryButton,
          }}
          onClick={() => setActiveAdvisorEmail()}
        >
          {"<"} Back to Advisors
        </Button>
      </Row>
      <Row style={{ gap: 40, marginBottom: 20 }}>
        <Space direction="vertical">
          <Typography.Paragraph style={{ fontSize: 22, margin: 0 }}>
            {advisorData?.email}
          </Typography.Paragraph>
          <Typography.Paragraph style={{ marginTop: 0, marginBottom: 38 }}>
            Financial Advisor
            <br />
            {advisorData?.userManager?.brokerDealerName ||
              advisorData?.userManager?.firmName}
          </Typography.Paragraph>
        </Space>
        <AnalyticValue
          label={"Total Clients"}
          value={getAdvisorClients().length}
        />
        <AnalyticValue
          label={"Total Proposed AUM"}
          value={getCurrencyValueWithRoundSign(
            getManagedUsersTotalProposedAmount(getAdvisorClients())
          )}
        />
      </Row>
      <Input
        style={{
          maxWidth: 290,
          height: 37,
          marginBottom: 16,
          borderRadius: 25,
        }}
        placeholder="Search Clients..."
        onChange={event => setSearchedText(event.target.value)}
      />
      <Table
        dataSource={getAdvisorTableData()}
        columns={getColumns({
          adminState,
          searchedText,
        })}
        pagination={false}
        loading={adminState.loading}
        scroll={{
          x: 1450,
        }}
      />
    </>
  );
};

export default AdvisorTableContainer;
