import { useContext } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import dayjs from "dayjs";
import State from "../../../../context/manager/State";
import { theme, Typography } from "antd";
import ChartSkeleton from "../../../../atoms/ChartSkeleton";

import chartConfig from "./configs/chartConfig";
import {
  getAllManagedUsersOfAdvisors,
  getLastSavedProposalProps,
  isPortalAdmin,
} from "../../../../utils/helpers/specialized";

const Top3ProductsBars = ({ productsList }) => {
  const { token } = theme.useToken();
  const [adminState] = useContext(State);

  const getTopSelectedProducts = () => {
    if (!adminState.investmentStrategies?.length && !productsList) {
      return [];
    }

    const dateBeforeTodayOn90Days = dayjs().subtract(90, "day");

    const strategiesNames = adminState.investmentStrategies
      ? adminState.investmentStrategies.map(it => it.name)
      : productsList.map(it => it.name);

    return strategiesNames
      .map(name => ({
        name,
        selected: (isPortalAdmin()
          ? getAllManagedUsersOfAdvisors(adminState.managedUserManagers)
          : (adminState.getManagedUsers() ?? [])
        ).reduce(
          (acc, it) =>
            (it.selectedProduct === name ||
              getLastSavedProposalProps(it.valueMap?.productMap)
                ?.productName === name) &&
            dayjs(
              getLastSavedProposalProps(it.valueMap?.productMap)?.timestamp ??
                it.proposalSavedDate?.$date
            ).isAfter(dateBeforeTodayOn90Days)
              ? acc + 1
              : acc,
          0
        ),
      }))
      .sort((a, b) => b.selected - a.selected)
      .slice(0, 3);
  };

  const getSeries = () => {
    return getTopSelectedProducts().map(({ name, selected }, index) => ({
      name,
      color: token.topProductsColors[index],
      data: [[index, selected]],
    }));
  };

  const updateChartConfig = {
    ...chartConfig,
    xAxis: {
      categories: getTopSelectedProducts().map(it => it.name),
    },
    series: getSeries(),
  };

  return (
    <div style={{ maxWidth: 700 }}>
      <Typography.Text style={{ fontSize: 16, color: token.colorNavy }}>
        <span style={{ fontWeight: 500 }}>Top 3 Products</span> - last 90 days
      </Typography.Text>
      <ChartSkeleton loading={adminState.loading} height={80}>
        <HighchartsReact highcharts={Highcharts} options={updateChartConfig} />
      </ChartSkeleton>
    </div>
  );
};

export default Top3ProductsBars;
