import styled from "styled-components";
import { Flex } from "antd";

const StylesContainer = styled(Flex)`
  .ant-tabs-top > .ant-tabs-nav::before {
    display: none;
  }

  .ant-tabs .ant-tabs-tab-btn {
    font-size: 18px;
  }
`;

export default StylesContainer;
