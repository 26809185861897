import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import TagManager from "react-gtm-module";

import { addDynamicScript } from "../utils/helpers/specialized";

const LIST_NON_HUBSPOT_DOMAINS = ["localhost"];
const LIST_GTM = ["go.ontrajectory.com"];

const trackHubspotPageView = path => {
  //required to use global variable
  var _hsq = (window._hsq = window._hsq || []);
  _hsq.push(["setPath", path]);
  _hsq.push(["trackPageView"]);
};

const Trackers = ({ children }) => {
  const locationFromHook = useLocation();

  useEffect(() => {
    if (!LIST_NON_HUBSPOT_DOMAINS.includes(location.hostname)) {
      addDynamicScript({
        async: true,
        id: "hs-script-loader",
        src: "//js.hs-scripts.com/22416949.js",
      });
    }

    if (LIST_GTM.includes(location.hostname)) {
      TagManager.initialize({
        gtmId: "GTM-N5THD7K",
      });
    }
  }, []);

  useEffect(() => {
    if (!LIST_NON_HUBSPOT_DOMAINS.includes(location.hostname)) {
      trackHubspotPageView(locationFromHook.pathname);
    }
  }, [locationFromHook]);

  return children;
};

export default Trackers;
