import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import ChartSkeleton from "../../../../../../atoms/ChartSkeleton";

const CardChartPie = ({
  active,
  containerHeight = 187,
  containerWidth = 160,
  inactive,
  loading,
  onClick,
  pieSize = 80,
  readonly = false,
  series,
  style = {},
  title,
}) => (
  <div
    onClick={() => !readonly && onClick()}
    style={{
      ...style,
      borderRadius: 12,
      boxShadow: active
        ? "0 19px 35px 0 rgba(0, 0, 0, 0.45)"
        : "0 3px 12px 0 rgba(0, 0, 0, 0.20)",
      cursor: readonly ? "default" : "pointer",
      height: "fit-content",
    }}
  >
    <ChartSkeleton height={350} loading={loading} width={300}>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          chart: {
            backgroundColor: active ? "#fff" : "#F6F7F8",
            borderRadius: 12,
            height: containerHeight,
            spacingBottom: 24,
            spacingTop: 24,
            shadow: false,
            type: "pie",
            width: containerWidth,
            style: {
              opacity: !active && inactive !== undefined && inactive ? 0.5 : 1,
            },
          },
          credits: {
            enabled: false,
          },
          exporting: {
            buttons: {
              contextButton: {
                enabled: false,
              },
            },
          },
          legend: false,
          plotOptions: {
            pie: {
              size: pieSize,
              innerSize: "60%",
              dataLabels: {
                enabled: false,
              },
              center: ["50%", "50%"],
              borderWidth: 0,
              showInLegend: true,
            },
          },
          title: {
            text: title,
            verticalAlign: "bottom",
            style: {
              fontSize: 16,
            },
          },
          tooltip: {
            outside: true,
          },
          series: [
            {
              data: series,
            },
          ],
        }}
      />
    </ChartSkeleton>
  </div>
);

export default CardChartPie;
