import dayjs from "dayjs";
import { Flex, Form, Input, InputNumber, Radio } from "antd";

import {
  cleanCurrencyValue,
  cleanPhoneMask,
  getCurrencyValue,
  selectValue,
  setPhoneMask,
} from "../../../../../../utils/helpers/general";

const TabUser = ({
  editView,
  isMarriedFormValue,
  isOneascent,
  managerAccess,
}) => (
  <>
    <Flex gap={32}>
      <Form.Item
        label={"First Name"}
        name={"firstName"}
        rules={[
          {
            required: true,
          },
        ]}
        style={{ width: "100%" }}
      >
        <Input onClick={selectValue} size={"large"} />
      </Form.Item>
      <Form.Item
        label={"Last Name"}
        name={"lastName"}
        rules={[
          {
            required: true,
          },
        ]}
        style={{ width: "100%" }}
      >
        <Input onClick={selectValue} size={"large"} />
      </Form.Item>
    </Flex>
    <Form.Item
      label={"Email"}
      name={"email"}
      rules={[
        {
          required: true,
        },
      ]}
    >
      <Input disabled={editView} onClick={selectValue} size={"large"} />
    </Form.Item>
    <Flex gap={32}>
      <Form.Item
        hidden={isOneascent}
        label={"Phone"}
        name={"phoneNumber"}
        style={{ width: "100%" }}
      >
        <InputNumber
          formatter={setPhoneMask}
          onClick={selectValue}
          parser={cleanPhoneMask}
          size={"large"}
          style={{ width: "100%" }}
        />
      </Form.Item>
      <Form.Item
        hidden={isOneascent}
        label={"Phone Alt"}
        name={"phoneAlt"}
        style={{ width: "100%" }}
      >
        <InputNumber
          formatter={setPhoneMask}
          onClick={selectValue}
          parser={cleanPhoneMask}
          size={"large"}
          style={{ width: "100%" }}
        />
      </Form.Item>
    </Flex>
    <Flex align={"end"} gap={32}>
      <Form.Item
        hidden={
          (!managerAccess?.affiliate && !managerAccess?.proportal) ||
          isOneascent
        }
        label={"Planning Type"}
        name={"planningType"}
        style={{ width: "100%" }}
      >
        <Radio.Group>
          {managerAccess?.affiliate && !managerAccess?.proportal ? (
            <Radio value={"Goals"}>Goals</Radio>
          ) : managerAccess?.proportal && !managerAccess?.affiliate ? (
            <Radio value={"Cashflow"}>Cashflow</Radio>
          ) : (
            <Flex vertical>
              <Radio value={"Goals"}>Goals</Radio>
              <Radio value={"Cashflow"}>Cashflow</Radio>
            </Flex>
          )}
        </Radio.Group>
      </Form.Item>
      <Form.Item
        hidden={isOneascent}
        label={"Initial Investable Assets"}
        name={"assets"}
        style={{ width: "100%" }}
      >
        <InputNumber
          formatter={getCurrencyValue}
          max={10000000}
          min={0}
          onClick={selectValue}
          parser={cleanCurrencyValue}
          size={"large"}
          step={1000}
          style={{ width: "100%" }}
        />
      </Form.Item>
    </Flex>
    <Flex gap={32}>
      <Form.Item
        label={"Marital Status"}
        name={"maritalStatus"}
        style={{ width: "100%" }}
      >
        <Radio.Group>
          <Flex vertical>
            <Radio value={"single"}>Single</Radio>
            <Radio value={"married"}>Married</Radio>
          </Flex>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        hidden={isOneascent}
        label={"Birth Year"}
        name={"yearBorn"}
        rules={[
          {
            type: "number",
            min: 1940,
            max: dayjs().format("YYYY") - 5,
          },
        ]}
        style={{ width: "100%" }}
      >
        <InputNumber
          id={"yearBornInput"}
          inputMode={"numeric"}
          onClick={selectValue}
          pattern={"[0-9]*"}
          placeholder={"YYYY"}
          style={{ width: "100%" }}
        />
      </Form.Item>
    </Flex>
    <Flex align={"end"} gap={32}>
      <Form.Item
        hidden={!isOneascent || !isMarriedFormValue}
        label={"Spouse's Name"}
        name={"spouseFirstName"}
        rules={[
          {
            required: true,
          },
        ]}
        style={{ width: "100%" }}
      >
        <Input onClick={selectValue} size={"large"} />
      </Form.Item>
      <Form.Item
        hidden={!isOneascent || !isMarriedFormValue}
        name={"spouseLastName"}
        rules={[
          {
            required: true,
          },
        ]}
        style={{ width: "100%" }}
      >
        <Input onClick={selectValue} size={"large"} />
      </Form.Item>
    </Flex>
    <Form.Item
      hidden={!isOneascent || !isMarriedFormValue}
      label={"Spouse's Email"}
      name={"spouseEmail"}
      rules={[
        {
          required: true,
        },
      ]}
    >
      <Input disabled={editView} onClick={selectValue} size={"large"} />
    </Form.Item>

    <Form.Item hidden name={"enableOnboardingQuestions"} />
    <Form.Item hidden name={"oldEmail"} />
    <Form.Item hidden name={"accounts"} />
    <Form.Item hidden name={"incomes"} />
    <Form.Item hidden name={"expenses"} />
  </>
);

export default TabUser;
