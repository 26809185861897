import styled from "styled-components";
import { Button, Modal } from "antd";

const StyledModal = styled(Modal)`
  top: 18px;

  &.ant-modal .ant-modal-content {
    max-height: 90vh;
    padding: 0;
    overflow: auto;
  }

  &.ant-modal .ant-modal-header {
    padding: 21px 35px 0;
  }

  & .ant-tabs .ant-tabs-ink-bar {
    background: #4897d1;
  }

  & .ant-tabs > .ant-tabs-nav,
  .ant-tabs > div > .ant-tabs-nav {
    padding-left: 35px;
    margin: 0;
  }

  & .ant-tabs .ant-tabs-content {
    min-height: 387px;
    padding: 25px 32px;
  }

  &.ant-modal .ant-modal-footer {
    padding: 0 35px 41px;
    margin: 0;
  }

  .ant-form-item {
    margin: 0;
  }

  .ant-form-vertical .ant-form-item-label {
    padding-bottom: 0;
  }

  .ant-form-item .ant-form-item-label > label {
    color: #8396a6;
  }

  .ant-input-number,
  .ant-input-number .ant-input-number-input,
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    color: #2a2828;
    background: #ebf1f3;
    border-color: #ebf1f3;
  }
`;

export const StyledButton = styled(Button)`
  background: #ebf1f3;
  border-color: transparent;

  .ant-btn-icon {
    position: absolute;
    left: 4px;
    top: 4px;
  }

  &.active {
    background: #3aaafc;
    color: #ffffff;
  }
`;

export default StyledModal;
